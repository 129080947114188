import store from "../../store"

export const setInterceptorConfig = (axios) => {
  axios.interceptors.response.use(function (response) {
    if (response.data && response.data.configApp) {
      store.dispatch('$a_setConfigApp', response.data.configApp)
    }

    return response
  })
}
