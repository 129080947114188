export default (axios, checkHeaders) => ({
  getList (data = {}) {
    return axios.get('/dunning-rules', { params: data.filters || {} })
  },
  postOne (data = {}) {
    return axios.post('/dunning-rule', data.body, { params: data.filters || {} })
  },
  getOne (data = {}) {
    return axios.get('/dunning-rule/' + data.id, { params: data.filters || {} })
  },
  patchOne (data = {}) {
    return axios.patch('/dunning-rule/' + data.id, data.body, { params: data.filters || {} })
  },
  deleteOne (data = {}) {
    return axios.delete('/dunning-rule/' + data.id, { params: data.filters || {} })
  }
})
