export default (axios, checkHeaders) => ({
  getList (data = {}) {
    return axios.get('/safe-files', { params: data.filters || {} })
  },
  postOne (data = {}) {
    return axios.post('/safe-file', data.body, { params: data.filters || {} })
  },
  getOne (data = {}) {
    return axios.get('/safe-file/' + data.id, { params: data.filters || {} })
  },
  patchOne (data = {}) {
    return axios.patch('/safe-file/' + data.id, data.body, { params: data.filters || {} })
  },
  deleteOne (data = {}) {
    return axios.delete('/safe-file/' + data.id, { params: data.filters || {} })
  }
})
