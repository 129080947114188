export default {
  toastSuccess: function (text, options = {}) {
    this.$root.$bvToast.toast(text, {
      title: options.title || 'Action réalisée avec succès',
      variant: "success",
      solid: true,
      static: true,
    })
  },
  toastWarning: function (text, options = {}) {
    this.$root.$bvToast.toast(text, {
      title: options.title || 'Attention !',
      variant: "warning",
      solid: true,
      static: true,
    })
  },
  toastInfo: function (text, options = {}) {
    this.$root.$bvToast.toast(text, {
      title: options.title || 'Informations',
      variant: "info",
      solid: true,
      static: true,
    })
  },
  toastError: function (err, options = {}) {
    let text = err.message

    if (err.statusCode === 422) {
      text = 'Oups!'
    }

    this.$root.$bvToast.toast(text, {
      title: options.title || 'Une erreur est survenue',
      variant: "danger",
      solid: true,
      static: true,
    })
  },
  toastDeleteSuccess: function (text, options = {}) {
    this.$root.$bvToast.toast(text, {
      title: options.title || 'Suppression réussie',
      variant: "success",
      solid: true,
      static: true,
    })
  },
  toastCreateSuccess: function (text, options = {}) {
    this.$root.$bvToast.toast(text, {
      title: options.title || 'Création réussie',
      variant: "success",
      solid: true,
      static: true
    })
  },
  toastUpdateSuccess: function (text, options = {}) {
    this.$root.$bvToast.toast(text, {
      title: options.title || 'Modification réussie',
      variant: "success",
      solid: true,
      static: true,
    })
  }
}
