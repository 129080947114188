<template>
  <div class="DocumentDefault">
    <main class="DocumentDefault">
      <transition name="fade" mode="out-in">
        <slot></slot>
      </transition>
    </main>
  </div>
</template>

<script>
export default {
  name: `DocumentDefault`
}
</script>

<style>

</style>
