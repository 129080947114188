import AppLayout from '../layouts/AppLayout'
import {resolveLoadingApp} from "@/utils/middlewares/auth.middleware"
import store from "@/store"

const beforeEnterCheckerSeeClients = function (to, from, next, redirect = { name: 'Error403' }) {
  let position = store.getters.currentPosition
  if (position) {
    let seeMy = store.getters.currentRights.SEE_MY_CONTACTS
    let seeTeam = store.getters.currentRights.SEE_TEAM_CONTACTS
    let seeAll = store.getters.currentRights.SEE_ALL_CONTACTS

    if (!seeMy && !seeTeam && !seeAll) return next(redirect)
  }

  next()
}

const beforeEnterCheckerAddClient = function (to, from, next, redirect = { name: 'Error403' }) {
  let position = store.getters.currentPosition
  if (position) {
    if (!store.getters.currentRights.CREATE_CONTACT && !store.getters.currentRights.UPDATE_CONTACT) return next(redirect)
  }

  next()
}

export default [
  {
    path: 'clients',
    name: 'ClientList',
    component: () => import('../pages/company/contact/ContactList.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerSeeClients(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'clients.menu', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/client/update/:id',
    name: 'ClientUpdate',
    component: () => import('../pages/company/contact/ClientUpdate.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddClient(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'clients.menu', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/client/create',
    name: 'ClientCreate',
    component: () => import('../pages/company/contact/ClientCreate.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddClient(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'clients.menu', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/clients/import',
    name: 'ClientImport',
    component: () => import('../pages/company/contact/ClientImport.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddClient(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'clients.import_title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/clients/merge',
    name: 'ClientMerge',
    component: () => import('../pages/company/contact/ClientMerge.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddClient(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'clients.merge.title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    // OLD URL REDIRECT TO NEW
    path: '/:company_url/client/show/:id',
    beforeEnter: async (to, from, next) => {
      return next({ name: 'Client', params: { company_url: to.params.company_url, id: to.params.id } })
    }
  },
  {
    path: '/:company_url/client/:id',
    name: 'Client',
    component: () => import('../pages/company/contact/Contact.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerSeeClients(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'clients.menu', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/client/:clientId/interlocutor/:id/update',
    name: 'InterlocutorUpdate',
    component: () => import('../pages/company/contact/InterlocutorUpdate.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddClient(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'clients.menu', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/client/:clientId/interlocutor/create',
    name: 'InterlocutorCreate',
    component: () => import('../pages/company/contact/InterlocutorCreate.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddClient(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'clients.menu', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/client/:clientId/interlocutor/:id',
    name: 'Interlocutor',
    component: () => import('../pages/company/contact/Interlocutor.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerSeeClients(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'clients.menu', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/reminders',
    name: 'ReminderList',
    component: () => import('../pages/company/contact/ReminderList'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerSeeClients(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'clients.menu', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/activities',
    name: 'ActivityList',
    component: () => import('../pages/company/contact/ActivityList'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerSeeClients(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'clients.menu', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  }
]
