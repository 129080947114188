import axios from 'axios'

export default (axios, getSignature) => ({
  getUser (data = {}) {
    let dateTime = new Date().getTime()

    let uri = '/user/' + data.id

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('GET', uri, dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.get(uri, { params: data.filters || {} })
  },
  companySiren (data = {}) {
    let dateTime = new Date().getTime()

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('GET', '/companies/sirene', dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.get('/companies/sirene', { params: data.filters || {} })
  },
  getCompanyCGV (data = {}) {
    let dateTime = new Date().getTime()

    const uri = '/company/' + data.id + '/cgv'

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('GET', uri, dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.get(uri, { params: data.filters || {} })
  },
  getCompanyList (data = {}) {
    let dateTime = new Date().getTime()

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('GET', '/companies', dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.get('/companies', { params: data.filters || {} })
  },
  getCompanyTypeList (data = {}) {
    let dateTime = new Date().getTime()

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('GET', '/company-types', dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.get('/company-types', { params: data.filters || {} })
  },
  getVatRateList (data = {}) {
    let dateTime = new Date().getTime()

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('GET', '/vat-rates', dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.get('/vat-rates', { params: data.filters || {} })
  },
  getCityList (data = {}) {
    let url = `https://geo.api.gouv.fr/communes?format=json&geometry=centre`

    if (data.filters) {
      if (data.filters.code) {
        url += `&codePostal=${data.filters.code}`
      }

      if (data.filters.nom) {
        url += `&nom=${data.filters.nom}`
      }

      if (data.filters.limit) url+= '&limit=' + data.filters.limit
    }

    return axios.get(url)
  },
  getWebsiteInfos (data = {}) {
    let dateTime = new Date().getTime()

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('POST', '/website-infos', dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.post('/website-infos', data.body, { params: data.filters || {} })
  },
  getCountries (data = {}) {
    let dateTime = new Date().getTime()

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('GET', '/countries', dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.get('/countries', { params: data.filters || {} })
  },
  getAbsenceTypeList (data = {}) {
    let dateTime = new Date().getTime()

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('GET', '/absence-types', dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.get('/absence-types', { params: data.filters || {} })
  },
  getInvoice (data = {}) {
    let dateTime = new Date().getTime()

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('GET', '/invoice/' + data.id, dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.get('/invoice/' + data.id, { params: data.filters || {} })
  },
  getQuote (data = {}) {
    let dateTime = new Date().getTime()

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('GET', '/quote/' + data.id, dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.get('/quote/' + data.id, { params: data.filters || {} })
  },
  getPayboxSession (data = {}) {
    let uri = '/paybox/checkout/session'

    let dateTime = new Date().getTime()

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('GET', uri, dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.get(uri, { params: data.filters || {} })
  },
  postPayboxDone (data = {}) {
    const SUBSCRIPTION_STRING = "PBX_2MONT0000000000PBX_NBPAIE00PBX_FREQ01PBX_QUAND00"
    // Remove subscription string for paybox "abonnements sur 12 mois"
    if (data.transactionId.indexOf(SUBSCRIPTION_STRING) > -1) {
      data.transactionId = data.transactionId.replace(SUBSCRIPTION_STRING, '')
    }

    let uri = `/paybox-paiement-done/${data.transactionId}`

    let dateTime = new Date().getTime()

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('GET', uri, dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.post(uri, data.body, { params: data.filters || {} })
  },
  getStripeSession (data = {}) {
    let uri = '/stripe/checkout/session'

    let dateTime = new Date().getTime()

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('GET', uri, dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.get(uri, { params: data.filters || {} })
  },
  checkoutStripeSession (data = {}) {
    let uri = '/stripe/checkout/session/' + data.session_id

    let dateTime = new Date().getTime()

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('GET', uri, dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.get(uri, { params: data.filters || {} })
  },
  getObjectCSV (data = {}) {
    let dateTime = new Date().getTime()

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('POST', '/csv/object', dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.post('/csv/object', data.body, { params: data.filters || {} })
  },
  createTicket (data = {}) {
    let dateTime = new Date().getTime()

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('POST', '/tickets', dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.post('/tickets', data.body, { params: data.filters || {} })
  },
  getContractList (data = {}) {
    let dateTime = new Date().getTime()

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('GET', '/contract-types', dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.get('/contract-types', { params: data.filters || {} })
  },
  getLeavingReasonList (data = {}) {
    let dateTime = new Date().getTime()

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('GET', '/leaving-reasons', dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.get('/leaving-reasons', { params: data.filters || {} })
  },
  getLanguageList (data = {}) {
    let dateTime = new Date().getTime()

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('GET', '/languages', dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.get('/languages', { params: data.filters || {} })
  },
  getCurrencyList (data = {}) {
    let dateTime = new Date().getTime()

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('GET', '/currencies', dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.get('/currencies', { params: data.filters || {} })
  },
  getModuleTypeList (data = {}) {
    let dateTime = new Date().getTime()

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('GET', '/module-types', dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.get('/module-types', { params: data.filters || {} })
  },
  getVersionList (data = {}) {
    let dateTime = new Date().getTime()

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('GET', '/versions', dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.get('/versions', { params: data.filters || {} })
  },
  getVersion (data = {}) {
    let dateTime = new Date().getTime()

    let uri = '/version/' + data.id

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('GET', uri, dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.get(uri, { params: data.filters || {} })
  },
  getCoupon (data = {}) {
    let dateTime = new Date().getTime()

    let uri = '/coupon/' + data.code

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('GET', uri, dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.get(uri, { params: data.filters || {} })
  },
  downloadPackage (data = {}) {
    let dateTime = new Date().getTime()

    let uri = '/package/' + data.id + '/download'

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('GET', uri, dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.get(uri, { params: data.filters || {}, responseType: 'arraybuffer', timeout: 100000 })
  },
  getLocale (data = {}) {
    let dateTime = new Date().getTime()

    let uri = '/locales/' + data.id

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('GET', uri, dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.get(uri, { params: data.filters || {} })
  },
  getRights (data = {}) {
    let dateTime = new Date().getTime()

    let uri = '/rights'

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('GET', uri, dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.get(uri, { params: data.filters || {} })
  },
  confirmDomain (data = {}) {
    return axios.patch('/company/' + data.id + '/domain-confirm', data.body, { params: data.filters })
  },
  getConfigApp (data = {}) {
    let dateTime = new Date().getTime()

    let uri = '/config-app'

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('GET', uri, dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.get(uri, { params: data.filters || {} })
  }
})
