import AppLayout from '../layouts/AppLayout'
import store from '../store'
import {resolveLoadingApp} from "@/utils/middlewares/auth.middleware"

const beforeEnterChecker = function (to, from, next) {
  let position = store.getters.currentPosition
  if (position) {
    if (!store.getters.currentRights.MANAGE_STATS) return next({ name: "Error403" })
  }

  next()
}

export default [
  {
    path: '/:company_url/statistics',
    name: 'Statistics',
    component: () => import('../pages/company/statistic/Statistics.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterChecker(to, from, next)
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'statistics.title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/statistics/sells',
    name: 'SellsStatistics',
    component: () => import('../pages/company/statistic/StatsGate.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterChecker(to, from, next)
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'statistics.sells', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/statistics/quotes',
    name: 'QuotesStatistics',
    component: () => import('../pages/company/statistic/StatsGate.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterChecker(to, from, next)
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'statistics.quotes', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/statistics/purchases',
    name: 'PurchasesStatistics',
    component: () => import('../pages/company/statistic/StatsGate.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterChecker(to, from, next)
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'statistics.title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/statistics/products',
    name: 'ProductsStatistics',
    component: () => import('../pages/company/statistic/StatsGate.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterChecker(to, from, next)
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'statistics.title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/statistics/hr',
    name: 'HRStatistics',
    component: () => import('../pages/company/statistic/StatsGate.vue'),
    beforeEnter: async (to, from, next) => {
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'statistics.title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  }
]
