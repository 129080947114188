import AutoSigned from './auto_signed'
import Company from './company'
import User from './user'
import TestAPI from './testAPI'

export default {
  autoSigned: AutoSigned,
  company: Company,
  user: User,
  testAPI: TestAPI,
}
