import Vue from 'vue'
import Router from 'vue-router'
import {ifAuthenticated, setCurrentUser, resolveLoadingApp} from '@/utils/middlewares/auth.middleware'
import {setCurrentCompany} from "@/utils/middlewares/company.middleware"
import store from '../store'
// Private
import AppLayout from '../layouts/AppLayout'
// Public
import DefaultLayout from '../layouts/DefaultLayout.vue'
import DocumentLayout from '../layouts/DocumentLayout.vue'
// Routes
import AccountRoutes from './account.router'
import AbsenceRoutes from './absence.router'
import BankReconciliationRoutes from "./bankReconciliation.router"
import ClientRoutes from './client.router'
import DeliveryReceipt from './deliveryReceipt.router'
import ExportRoutes from './export.router'
import InvoiceRoutes from './invoice.router'
import NotificationsRoutes from './notifications.router'
import ParamsRoutes from './params.router'
import ProductRoutes from './product.router'
import PurchaseRoutes from './purchase.router'
import QuoteRoutes from './quote.router'
import Safe from './safe.router'
import StatisticsRoutes from './statistics.router'
import SubscriptionRoutes from './subscription.router'
import TeamRoutes from './team.router'
import ErrorRoutes from './error.router'
import ato from "await-to-js"
import api from "../utils/api_factory"

Vue.use(Router)

let routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../pages/Login.vue'),
    beforeEnter: async (to, from, next) => {
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'login.title', layout: DefaultLayout }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../pages/Register.vue'),
    beforeEnter: async (to, from, next) => {
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'register.title', layout: DefaultLayout }
  },
  { path: '/confirmation', name: 'Confirmation', redirect: { name: 'RegisterConfirm' } }, // Ancienne URL mais elle doit toujours fonctionner
  {
    path: '/register/confirm',
    name: 'RegisterConfirm',
    component: () => import('../pages/RegisterConfirm.vue'),
    beforeEnter: async (to, from, next) => {
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'register.confirm.title', layout: DefaultLayout }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('../pages/ResetPassword.vue'),
    beforeEnter: async (to, from, next) => {
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'register.confirm.title', layout: DefaultLayout }
  },
  {
    path: '/reset-password/confirm',
    name: 'ResetPasswordConfirm',
    component: () => import('../pages/ResetPasswordConfirm.vue'),
    beforeEnter: async (to, from, next) => {
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'reset_password.title', layout: DefaultLayout }
  },
  {
    path: '/confirm-invite',
    name: 'ConfirmInvite',
    component: () => import('../pages/InvitationConfirm.vue'),
    beforeEnter: async (to, from, next) => {
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'params.invite.confirm_invite', layout: DefaultLayout, noNavigation: true }
  },
  {
    path: '/company/add',
    name: 'AddCompany',
    component: () => import('../pages/user/AddCompany.vue'),
    beforeEnter: async (to, from, next) => {
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'params.add_company.title_nav', layout: DefaultLayout, authenticate: true, noNavigation: true }
  },
  {
    path: '/company/:id/confirm-domain',
    name: 'DomainConfirm',
    component: () => import('../pages/DomainConfirm.vue'),
    beforeEnter: async (to, from, next) => {
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'register.confirm.title', layout: DefaultLayout }
  },
  {
    path: '/cgv-public/:id',
    name: 'CGVPublic',
    component: () => import('../pages/company/CGVPublic'),
    beforeEnter: async (to, from, next) => {
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'cgv.title', layout: DocumentLayout }
  },
  {
    path: '/',
    name: 'WuroHome',
    beforeEnter: async (to, from, next) => {
      const navigationsData = store.getters.navigationsData
      if (navigationsData && navigationsData.length > 0) {
        let firstNav = navigationsData[0]

        if (firstNav.company_url) {
          return next({ name: 'Home', params: { company_url: firstNav.company_url } })
        }
      }

      return next({ name: "Account" })
    },
    meta: { noNavigation: true }
  },
  {
    path: '/posao',
    name: 'Posao',
    beforeEnter: async function (to, from, next) {
      let currentPositions = store.getters.currentPositions

      if (currentPositions && currentPositions.length) {
        let firstPosition = currentPositions[0]
        if (firstPosition) {
          return next({ name: "Home", params: { company_url: firstPosition.company.url } })
        }
      }

      return next({ name: 'Account' })
    }
  },
  // ANCIENNES ROUTES MAIS DOIVENT TOUJOURS ETRE DISPONIBLES
  {
    path: '/invoice/html/:id',
    name: 'InvoiceDocumentHtmlWithoutCompanyUrl',
    component: () => import('../pages/document/InvoiceShow.vue'),
    beforeEnter: async (to, from, next) => {
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'account.my_account', layout: DocumentLayout }
  },
  {
    path: '/invoice/pdf/:id',
    name: 'InvoiceDocumentPdfWithoutCompanyUrl',
    beforeEnter: async (to, from, next) => {
      let [err, resp] = await ato(api.autoSigned.service.getInvoice({ id: to.params.id, filters: { populate: 'company' } }))
      if (err) {
        if (err.response.status === 404) return next({ name: 'Error404' })

        if (err) return next({ name: 'Error500' })
      }

      if (resp.data.invoice && resp.data.invoice.company && resp.data.invoice.company.url) {
        return next({ name: 'InvoiceDocumentPdf', params: { id: to.params.id, company_url: resp.data.invoice.company.url } })
      } else {
        return next({ name: 'Error500' })
      }
    },
  },
  // ANCIENNES ROUTES MAIS DOIVENT TOUJOURS ETRE DISPONIBLES
  {
    path: '/quote/html/:id',
    name: 'DocumentHtmlWithoutCompanyUrl',
    component: () => import('../pages/document/QuoteShow.vue'),
    beforeEnter: async (to, from, next) => {
      await resolveLoadingApp(to, from, next)
    }
  },
  {
    path: '/quote/pdf/:id',
    name: 'DocumentPdfWithoutCompanyUrl',
    beforeEnter: async (to, from, next) => {
      let [err, resp] = await ato(api.autoSigned.service.getQuote({ id: to.params.id, filters: { populate: 'company' } }))
      if (err) {
        if (err.response.status === 404) return next({ name: 'Error404' })

        if (err) return next({ name: 'Error500' })
      }

      if (resp.data.invoice && resp.data.invoice.company && resp.data.invoice.company.url) {
        return next({ name: 'DocumentPdf', params: { id: to.params.id, company_url: resp.data.invoice.company.url } })
      } else {
        return next({ name: 'Error500' })
      }
    },
  },
  {
    path: '/delivery-receipt/html/:id',
    name: 'DeliveryReceiptHtmlWithoutCompanyUrl',
    component: () => import('../pages/document/DeliveryReceiptShow.vue'),
    beforeEnter: async (to, from, next) => {
      await resolveLoadingApp(to, from, next)
    }
  },
  {
    path: '/account',
    components: {
      default: () => import('../layouts/subLayout/AccountLayout')
    },
    children: AccountRoutes
  },
  {
    path: '/:company_url',
    components: {
      navSideBar: () => import('../components/NavCompany'),
      default: () => import('../layouts/subLayout/CompanyLayout')
    },
    children: [
      ...[
        {
          path: '/',
          name: 'CompanyHomeNothing',
          beforeEnter: async (to, from, next) => {
            return next({ name: 'Home', params: { company_url: to.params.company_url } })
          },
        },
        {
          path: 'home',
          name: 'Home',
          component: () => import('../pages/company/Dashboard.vue'),
          beforeEnter: async (to, from, next) => {
            await resolveLoadingApp(to, from, next)
          },
          meta: { title: 'home.title', layout: AppLayout, activeMenu: 'app', authenticate: true, company: true }
        },
        {
          path: 'help',
          name: 'Help',
          component: () => import('../pages/company/Help.vue'),
          beforeEnter: async (to, from, next) => {
            await resolveLoadingApp(to, from, next)
          },
          meta: { title: 'help.title', layout: AppLayout, activeMenu: 'help', authenticate: true, company: true }
        },
      ],
      ...AbsenceRoutes,
      ...BankReconciliationRoutes,
      ...ClientRoutes,
      ...DeliveryReceipt,
      ...ExportRoutes,
      ...InvoiceRoutes,
      ...NotificationsRoutes,
      ...ParamsRoutes,
      ...ProductRoutes,
      ...PurchaseRoutes,
      ...QuoteRoutes,
      ...Safe,
      ...StatisticsRoutes,
      ...SubscriptionRoutes,
      ...TeamRoutes
    ]
  },
  ErrorRoutes,
  {
    path: "*",
    name: '404',
    component: () => import('../pages/errors/404'),
    beforeEnter: async (to, from, next) => {
      await resolveLoadingApp(to, from, next)
    }
  }
]

routes = routes.flat()

export default (i18n) => {
  const router = new Router({
    mode: 'history',
    routes: routes
  })

  if (i18n) {
    router.beforeEach(async (to, from, next) => {
      // Quentin stuff
      if (process.env.NODE_ENV === 'prod' || process.env.NODE_ENV === 'production') {
        const user = store.getters.currentUser
        const company = store.getters.currentCompany
        if (company && user && store.getters.isAuthenticated) {
          let companyName = company.name ? ' ' + company.name : ''
          // Update title & description according to route metas
          document.title = (to.meta.title && to.meta.title !== '') ? i18n.t(to.meta.title) + ' • ' + companyName : companyName
          window.dataLayer = window.dataLayer || []

          let isCreator = user.created_companies ? user.created_companies.find(cId => cId === company._id) : null

          window.dataLayer.push({
            'event': 'page_view',
            'pagePath': to.fullPath,
            'pageTitle': i18n.t(to.meta.title),
            'email': user.email,
            'dateCreation': company.createdAt,
            'role': store.getters.currentPosition.name,
            'company': company.name,
            'offre': company.version ? company.version.name : 'gratuit',
            'isCreator': !!isCreator,
          })
        }
      }

      await setCurrentUser(to, from, next)

      if (to.meta.authenticate) await ifAuthenticated(to, from, next)
      if (to.meta.company) await setCurrentCompany(to, from, next)
      if (!to.meta.noNavigation && to.meta.authenticate) {
        store.dispatch('$a_setNavigation', to).catch(err => console.error(err))
      }

      if (to.meta.title) {
        let title = i18n.t(to.meta.title) || ''
        if (to.meta.company) {
          let currentCompany = store.getters.currentCompany
          if (currentCompany) {
            title += ' - ' + currentCompany.name
          }
        }
        document.title = title
      }

      store.dispatch('$a_setNbNotifications').catch(err => console.error(err))

      return next()
    })
  }

  return router
}
