<template>
  <b-icon v-if="displayFecFields"
          :animation="animation"
          class="fec-icon"
          variant="orange"
          icon="exclamation-triangle-fill"
          v-b-tooltip.hover="tooltip"
          :font-scale="fontScale"
  ></b-icon>
</template>

<script>
import {mapGetters} from "vuex"

export default {
  name: 'FECIcon',
  props: {
    animation: {
      type: String,
      default: "",
    },
    tooltip: {
      type: String,
      default: 'Veillez à compléter ces champs si vous souhaitez un FEC complet.'
    },
    fontScale: {
      type: String,
      default: '1.5'
    }
  },
  computed: {
    ...mapGetters(['displayFecFields']),
  },
}
</script>
