import to from "await-to-js"
import api from "../../utils/api_factory"
import {CURRENT_RECEIPT} from "../mutation.constant"
import {getNewDeliveryReceipt} from "@/components/Document/document.constant"

const state = {
  currentDeliveryReceipt: {},
  filters: localStorage.getItem('deliveryReceiptFilters') ? JSON.parse(localStorage.getItem('deliveryReceiptFilters')) : {}
}

const getters = {
  currentDeliveryReceipt: state => state.currentDeliveryReceipt,
  getDeliveryReceiptFilters: () => state.filters
}

const actions = {
  $a_getCurrentDeliveryReceipt: async function ({ commit, dispatch, rootState, state }, { id, type, documentModels, countries, client }) {
    if (!id) {
      let deliveryReceipt = await getNewDeliveryReceipt(rootState.company.currentCompany, rootState.authV3.currentPosition, rootState.authV3.currentUser, type, documentModels, countries, client)
      commit(CURRENT_RECEIPT, deliveryReceipt)
    } else {
      let [err, resp] = await to(api.company.deliveryReceipt.getOne({ id, filters: { populate: '' } }))
      if (err) return err

      let deliveryReceipt = resp.data.receipt
      if (!deliveryReceipt.style) deliveryReceipt.style = {}
      if (!deliveryReceipt.style.footer) deliveryReceipt.style.footer = {}

      commit(CURRENT_RECEIPT, deliveryReceipt)
    }
  }
}

const mutations = {
  [CURRENT_RECEIPT]: (state, deliveryReceipt) => {
    state.currentDeliveryReceipt = deliveryReceipt
  },
  deliveryReceiptFilters (state, filters) {
    let newFilter = {}
    newFilter.state = filters.state
    newFilter.date = filters.date
    newFilter.minDate = filters.minDate
    newFilter.maxDate = filters.maxDate
    localStorage.setItem('deliveryReceiptFilters', JSON.stringify(newFilter))
    state.filters = { ...newFilter }
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
