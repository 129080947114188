<template>
  <b-alert :show="!currentCompany.options.dunning" variant="warning">
    En souscrivant une offre supérieure, vous accéderez à la fonctionnalité Relances automatiques. Vous pourrez ainsi définir des règles de relance et programmer sur vos factures des dates de relances par email en cas de retard de paiement.    <br>
    <router-link :to="{ name: 'Subscription', params: { company_url: currentCompany.url } }">Consultez les offres</router-link>
  </b-alert>
</template>

<script>
export default {
  name: 'AlertDunningVersion'
}
</script>
