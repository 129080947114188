<template>
  <div :class="classLayoutShell">
    <ActionsSubmenu v-if="menuItems.length > 0" :class="menuItems[0].group" :menu-items="menuItems"/>

      <div class="container-xxl">
        <div v-if="tabItems && tabItems.length > 0" class="menu-tabs mt-4">
          <router-link v-for="(tabItem, i) in tabItems" :key="'tab-item-' + i"
                      v-if="checkRights(tabItem, currentPosition)"
                      class="tab"
                      :class="{ 'active': tabItem.active }"
                      :to="tabItem.route">
            {{ tabItem.label || "" }}

            <b-badge v-if="tabItem.badge !== undefined && tabItem.badge !== false"
                    :variant="tabItem.badgeVariant"
                    class="ml-1">
              {{ tabItem.badge }}
            </b-badge>
          </router-link>
        </div>

        <b-alert v-for="(message, index) in messages" :key="'global-message-' + index" show class="mt-3" :variant="message.variant ? message.variant : 'info'">
          <div v-html="message.html"></div>
        </b-alert>

        <transition name="inner" mode="out-in">
          <router-view/>
        </transition>
      </div>
  </div>
</template>

<script>
// Libs & Helpers
import {checkRights} from '@/utils/rights.service'
import moment from "moment"
// Components
import ActionsSubmenu from '../../components/ActionsSubmenu.vue'
import {mapGetters} from "vuex"

export default {
  name: 'CompanyLayout',
  components: {ActionsSubmenu},
  data () {
    return {
      menuItems: [],
      tabItems: [],
      selectedPosition: null,
      checkRights,
    }
  },
  computed: {
    ...mapGetters(['globalMessages']),
    messages () {
      return this.globalMessages.filter(m => {
        if (!m.routes) return true
        return m.routes.includes(this.$route.name)
      })
    },
    classLayoutShell () {
      if (this.$route.name === 'Home') return 'LayoutShell LayoutShellHome'
      return 'LayoutShell'
    }
  },
  created () {
    this.$on('updateTitle', (title) => {
      this.title = title

      if (this.$route.meta.company) {
        document.title = title + ' - ' + this.currentCompany.name
      }
    })
    this.$on('updateMenu', (menuItems) => {
      this.menuItems = menuItems
    })
    this.$on('updateTabs', (tabItems) => {
      this.tabItems = tabItems
    })
  }
}
</script>

<style scoped>
.notif-icon {
  position: fixed;
  top: 209px;
  left: 47px;
  z-index: 99;
}

.notif-icon > .parent {
  background: #a7c9ff;
}

.notif-icon > .parent::after {
  background: #a7c9ff;
}

.notif-icon > .parent::before {
  background: #a7c9ff;
}

.online {
  display: none;
}

.offline {
  position: fixed;
  z-index: 9999;
  width: 100%;
  padding: 10px;
  top: 0;
  background-color: #4bddd3;;
  background-image: linear-gradient(315deg, #37aeb3 0%, #99e0dc 74%);
}

.LayoutShell {
  width: 100%;
  height: 100%;
}

.main {
  position: relative;
  z-index: 12;
  margin-left: 90px;
  padding: 65px 0 0 0;
  background-color: #fbfbfb;
}

.actions.sub-menu .more-items button ion-icon {
  padding: 12px 16px;
  min-height: 22px;
}

.actions.sub-menu .more-items .dropdown-menu a:hover {
  text-decoration: none;
}

.summary {
  color: #0B0140;
  padding: 12px;
  font-weight: 600;
}

/** FAB **/
.fl-act-btn {
  display: none !important;
  bottom: 136px !important;
}

.fab-main-container .fab-items, .fab-main-container .vue-fab-material-icons {
  font-size: 20px !important;
}

.fab-main-container .icons {
  font-size: 26px !important;
}

.fl-act-btn .fab-item-title {
  color: #fff !important;
  margin: 1px;
  font-size: 1em !important;
  background: #69727d !important;
  line-height: 15px;
  min-width: 20px !important;
  padding: 10px !important;
  border-radius: 8px !important;
}

.show-params-alert {
  position: fixed;
  top: 278px;
  left: 47px;
  z-index: 99;
}

.v-step {
  z-index: 999;
  background: #ffffff !important;
  color: #444 !important;
}

.v-step .v-step__button {
  border-color: #444 !important;
  color: #444 !important;
}

.v-step .v-step__arrow {
  border-color: #FFF;
  border-style: solid;
  height: 0;
  margin: .5rem;
  position: absolute;
  width: 0
}

.v-step .v-step__arrow--dark {
  border-color: #FFF
}

.v-step[x-placement^=top] .v-step__arrow {
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-width: .5rem .5rem 0;
  bottom: -.5rem;
  left: calc(50% - 1rem);
  margin-bottom: 0;
  margin-top: 0
}

.v-step[x-placement^=bottom] {
  margin-top: .5rem
}

.v-step[x-placement^=bottom] .v-step__arrow {
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  border-width: 0 .5rem .5rem;
  left: calc(50% - 1rem);
  margin-bottom: 0;
  margin-top: 0;
  top: -.5rem
}

.v-step[x-placement^=right] .v-step__arrow {
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-top-color: transparent;
  border-width: .5rem .5rem .5rem 0;
  left: -.5rem;
  margin-left: 0;
  margin-right: 0;
  top: calc(50% - 1rem)
}

.v-step[x-placement^=left] .v-step__arrow {
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  border-width: .5rem 0 .5rem .5rem;
  margin-left: 0;
  margin-right: 0;
  right: -.5rem;
  top: calc(50% - 1rem)
}

@media only screen and (min-width: 768px) {

  .actions.sub-menu div.dropdown-item i {
    font-size: 21px;
    vertical-align: middle;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .actions.sub-menu .more-items {
    display: none !important;
  }

  .show-params-alert {
    top: auto;
    left: calc(100vw / 2 - 40px);
    bottom: 40px;
  }

  .fl-act-btn {
    display: flex !important;
  }

  .main {
    margin-left: 0 !important;
    padding: 4em 0 6em 0;
  }

  .img-circle {
    width: 20px;
  }

  #__w_mobile_footer > .mobile-links {
    width: 100%;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
  }

  #__w_mobile_footer .img-circle,
  #__w_mobile_account_modal .img-circle {
    border-radius: 50%;
  }

  .actions.sub-menu div.dropdown-item {
    display: none !important;
  }

  #__w_mobile_account_modal .img-circle {
    width: 50px;
  }

  table.b-table.b-table-stacked-md > tbody > tr > :first-child {
    border-top-width: 0;
  }

  .summary {
    text-align: center;
    line-height: 2;
  }


  #__w_mobile_account_modal {
    position: fixed;
    bottom: -300px;
    left: 0;
    width: 100vw;
    background: rgb(255, 255, 255);
    padding: 3em 0 1em 0;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    z-index: 1000;
    transition: bottom 0.4s ease-in-out;
  }

  #__w_mobile_account_modal.show {
    bottom: 45px;
  }

  #__w_mobile_profile_name {
    font-weight: 700;
    font-size: 23px;
    color: #0B0140;
  }

  #__w_mobile_profiles_settings {
    border-top: 1px solid;
    border-bottom: 1px solid;
    padding: 13px 5px;
    display: block;
    border-color: #e4e4e4;
    font-size: 15px;
  }

  #__w_mobile_profiles_logout {
    background: #ffcece;
    padding: 5px 10px;
    display: block;
    width: 40%;
    margin: 0 auto;
    border-radius: 4px;
    color: #ff4b4b;
  }

  .footer,
  .menu,
  .actions.sub-menu .more-items button,
  .w_actions_button > span {
    display: none;
  }

  .inline:not(.search) {
    width: calc(49.4%) !important;
  }

  .add-company.wuro-link {
    background: #00b6ad;
    color: #ffffff;
    width: 80%;
    border-radius: 4px;
    padding: 0;
    display: block;
    position: absolute;
    bottom: 120px;
    right: 23px;
    height: 36px;
  }

  /*FOOTER MOBILE*/
  #__w_mobile_footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1001;
    background: #f9f9f9;
    border-top: 1px solid #dde2e6;
    height: 60px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  #__w_mobile_footer a {
    color: #757575;
    font-size: 23px;
    width: 20px;
    padding: 10px;
    height: 100%;
  }

  .menu .drop-profile {
    padding: 0;
  }

  .menu > img {
    width: 65px;
    height: auto;
    padding: 15px 16px;
  }

  .menu-tabs > a {
    padding: 0 10px 10px 10px;
    cursor: pointer;
    margin-left: 10px;
  }
}



</style>
