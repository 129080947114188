export default (axios, checkHeaders) => ({
  getList (data = {}) {
    return axios.get('/purchase-tva-counts', { params: data.filters || {} })
  },
  postOne (data = {}) {
    return axios.post('/purchase-tva-count', data.body, { params: data.filters || {} })
  },
  getOne (data = {}) {
    return axios.get('/purchase-tva-count/' + data.id, { params: data.filters || {} })
  },
  patchOne (data = {}) {
    return axios.patch('/purchase-tva-count/' + data.id, data.body, { params: data.filters || {} })
  },
  deleteOne (data = {}) {
    return axios.delete('/purchase-tva-count/' + data.id, { params: data.filters || {} })
  }
})
