export default (axios, checkHeaders) => ({
  postOne (data = {}) {
    return axios.post('/subscription', data.body, { params: data.filters || {}})
  },
  patchOne (data = {}) {
    return axios.patch(`/subscription/${data.id}`, data.body, { params: data.filters || {} })
  },
  checkoutSession (data = {}) {
    return axios.post('/subscription/stripe/checkout-session', data.body, { params: data.filters || {} })
  },
  getSessionPortail (data = {}) {
    return axios.get('/subscription/portail', { params: data.filters || {} })
  },
  getInvoices (data = {}) {
    return axios.get('/subscription/invoices', { params: data.filters || {} })
  },
  getCurrentSubscriptions (data = {}) {
    return axios.get('/subscriptions', { params: data.filters || {} })
  }
})
