<template>
  <div>
      <b-card v-for="category in categories" :key="'category-safe-'+category.name" class="mb-3 pointer" @click="() => onClick(category)">
        <div class="d-flex justify-content-between">
          <div class="d-flex">
            <img width="70" class="img-fluid mr-3" :src="getImgCategory(category)"/>
            <div>
              <h3>{{ $t("safe.category." + category.name + ".label") }}</h3>
              <div>{{ $t("safe.category." + category.name + ".description") }}</div>
            </div>
          </div>
          <div>
            <b-badge variant="orange">{{ category.statsFiles}}</b-badge>
          </div>
        </div>
      </b-card>
  </div>
</template>

<script>
// Mixins
import SafeMixin from "@/pages/company/safe/subComponents/safe.mixin"
import {mapGetters} from "vuex"

export default {
  name: 'Safe',
  mixins: [SafeMixin],
  props: {
    positionId: {
      type: String,
      default: null
    },
    configCategories: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      category: null,
      categories: [],
    }
  },
  computed: {
    ...mapGetters(['currentCompanyPositions']),
    position () {
      let positionId = this.$route.params.position || this.positionId
      if (!positionId) return null

      return this.currentCompanyPositions.find(p => p._id === positionId)
    }
  },
  watch: {
    $route (to, from) {
      this.initCategories()
    }
  },
  created () {
    this.initCategories()
  },
  methods: {
    getImgCategory (category) {
      if (category.img) return process.env.VUE_APP_LOCAL_URL + '/img/safe/' + category.img
      return process.env.VUE_APP_LOCAL_URL + '/img/factures.svg'
    },
    initCategories () {
      if (!this.$route.params.category) {
        this.category = null
        this.categories = this.configCategories
      }
      if (this.$route.params.category) {
        let category = this.findCategory(this.$route.params.category, this.configCategories)
        if (category && category.children && category.children.length > 0) {
          this.category = category
          this.categories = category.children
        }
      }

      if (this.categories && this.categories.length > 0) {
        this.categories = this.categories.map(category => {
          if (this.position) {
            category.statsFiles = this.foundPositionStatFiles(category.file_type, this.position)
          } else {
            category.statsFiles = this.foundCompanyStatFiles(category.file_type)
          }

          return category
        })

        this.categories = this.categories.filter(category => {
          if (category.isSalaried && !this.position.isSalaried) return false

          return true
        })

        return
      }

      return this.$router.push({ name: 'Error403' })
    },
    onClick (category) {
      if (category.onPositionCategoryClickRoute) {
        return this.$router.push(category.onPositionCategoryClickRoute(this.position._id, this))
      }

      let params = { company_url: this.currentCompany.url, category: category.name }
      if (this.position) params.position = this.position._id

      return this.$router.push({ name: category.routeName, params })
    }
  }
}
</script>
