import store from "../../store"
import to from 'await-to-js'

export const setCurrentUser = async (toFrom, from, next) => {
  if (store.getters.isLoadingApp && !store.getters.isAuthenticated) {
    let byPassPosao = false
    if (toFrom.path === '/posao') {
      let email = toFrom.query.email || 'administrateur@posao.fr'
      const password = 'patrickroux'

      await store.dispatch('$a_loginByPassword', { email, password }).then(() => {
        byPassPosao = true
      })
    }

    // Si la connexion n'a pas déja été faite par une connexion au compte Posao
    if (!byPassPosao) {
      let [err] = await to(store.dispatch('$a_setCurrentUser'))
      if (err && store.getters.currentUserToken) {
        await store.dispatch('$a_logout')
        await store.dispatch('$a_stopLoadingAppInfos')
        return next({ name: 'Login' })
      }
    }

    if (store.getters.isAuthenticated) {
      let err = await store.dispatch('$a_loadUserDatas')
      if (err) {
        await store.dispatch('$a_logout')
        await store.dispatch('$a_stopLoadingAppInfos')
        return next({ name: 'Login' })
      }
    }
  }
}

export const ifAuthenticated = async (toFrom, from, next) => {
  if (store.getters.isAuthenticated) {
    return
  }

  await store.dispatch('$a_setTriggerRoute', toFrom)
  await store.dispatch('$a_stopLoadingAppInfos')
  return next({ name: 'Login' })

  // Ici on ne trigger pas next ici car c'est un middleware "intermédiaire", seul le dernier middleware trigger le next(), resolveLoadingApp
}

export const resolveLoadingApp = async (toFrom, from, next) => {
  if (store.getters.isLoadingApp) {
    await store.dispatch('$a_stopLoadingAppInfos')
    await store.dispatch('$a_stopLoadingCompany')
  }

  // Ici on trigger next car c'est le dernier fake middleware du router
  return next()
}
