import AppLayout from '../layouts/AppLayout'
import {resolveLoadingApp} from "@/utils/middlewares/auth.middleware"
import store from "@/store"

const beforeEnterCheckerSeePurchases = function (to, from, next, redirect = { name: 'Error403' }) {
  let position = store.getters.currentPosition
  if (position) {
    let seeAll = store.getters.currentRights.SEE_ALL_PURCHASES
    let seeBasket = store.getters.currentRights.SEE_MY_BASKETS

    if (!seeAll) {
      if (seeBasket) return next({ name: 'PurchaseBasketsList', params: { company_url: store.getters.currentCompany.url } })

      return next(redirect)
    }
  }

  next()
}

const beforeEnterCheckerAddPurchase = function (to, from, next, redirect = { name: 'Error403' }) {
  let position = store.getters.currentPosition
  if (position) {
    if (!store.getters.currentRights.CREATE_PURCHASE && !store.getters.currentRights.UPDATE_PURCHASE) return next(redirect)
  }

  next()
}

const beforeEnterCheckerSeeBasket = function (to, from, next, redirect = { name: 'Error403' }) {
  let position = store.getters.currentPosition
  if (position) {
    if (!store.getters.currentRights.SEE_MY_BASKETS) return next(redirect)
  }

  next()
}

const beforeEnterCheckerAddBasket = function (to, from, next, redirect = { name: 'Error403' }) {
  let position = store.getters.currentPosition
  if (position) {
    if (!store.getters.currentRights.CREATE_BASKET) return next(redirect)
  }

  next()
}

export default [
  // PURCHASES CATEGORIES
  {
    path: '/:company_url/purchase/category/create',
    name: 'PurchaseCategoryAdd',
    component: () => import('../pages/company/purchaseCategory/PurchaseCategoryCreate'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddPurchase(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'purchase_categories.title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/purchase/category/update/:id',
    name: 'PurchaseCategoryEdit',
    component: () => import('../pages/company/purchaseCategory/PurchaseCategoryUpdate'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddPurchase(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'purchase_categories.title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/purchases/categories',
    name: 'PurchaseCategoriesList',
    component: () => import('../pages/company/purchaseCategory/PurchaseCategoryList'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerSeePurchases(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'purchases.add', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  // PURCHASES
  {
    path: '/:company_url/purchases',
    name: 'PurchasesList',
    component: () => import('../pages/company/purchase/PurchaseList'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerSeePurchases(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'purchases.title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/purchase/create',
    name: 'PurchaseCreate',
    component: () => import('../pages/company/purchase/PurchaseForm'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddPurchase(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'purchases.add', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/purchase/create/:client_id',
    name: 'PurchaseAddClient',
    component: () => import('../pages/company/purchase/PurchaseForm'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddPurchase(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'purchases.add', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/purchase/update/:id',
    name: 'PurchaseUpdate',
    component: () => import('../pages/company/purchase/PurchaseForm'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddPurchase(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'purchases.add', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  // PURCHASE TVA COUNT
  {
    path: '/:company_url/purchase/tva-count/create',
    name: 'PurchaseTvaCountCreate',
    component: () => import('../pages/company/purchaseTvaCount/PurchaseTvaCountCreate'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddPurchase(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'purchase_categories.title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/purchase/tva-count/update/:id',
    name: 'PurchaseTvaCountUpdate',
    component: () => import('../pages/company/purchaseTvaCount/PurchaseTvaCountUpdate'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddPurchase(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'purchase_categories.title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/purchases/tva-counts',
    name: 'PurchaseTvaCountList',
    component: () => import('../pages/company/purchaseTvaCount/PurchaseTvaCountList'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerSeePurchases(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'purchases.add', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  // PURCHASE EXPENSE COUNT
  {
    path: '/:company_url/purchase/expense-count/create',
    name: 'PurchaseExpenseCountCreate',
    component: () => import('../pages/company/purchaseExpenseCount/PurchaseExpenseCountCreate'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddPurchase(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'purchase_categories.title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/purchase/expense-count/update/:id',
    name: 'PurchaseExpenseCountUpdate',
    component: () => import('../pages/company/purchaseExpenseCount/PurchaseExpenseCountUpdate'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddPurchase(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'purchase_categories.title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/purchases/expense-counts',
    name: 'PurchaseExpenseCountList',
    component: () => import('../pages/company/purchaseExpenseCount/PurchaseExpenseCountList'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerSeePurchases(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'purchases.add', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  // BASKET
  {
    path: '/:company_url/purchase/baskets',
    name: 'PurchaseBasketsList',
    component: () => import('../pages/company/purchase/PurchaseBasketList'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerSeeBasket(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'purchases.title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/purchase/basket/create',
    name: 'PurchaseBasketCreate',
    component: () => import('../pages/company/purchase/PurchaseBasketCreate'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddBasket(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'purchases.title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  }
]
