import {resolveLoadingApp} from "@/utils/middlewares/auth.middleware"
import AppLayout from "@/layouts/AppLayout"

export default [
  {
    path: '/:company_url/positions',
    name: 'CompanyPositions',
    component: () => import('../pages/company/team/CompanyPositions'),
    beforeEnter: async (to, from, next) => {
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: '', layout: AppLayout, activeMeny: 'app', authenticate: true, company: true }
  },
  {
    path: '/:company_url/position/:id',
    name: 'Position',
    component: () => import('../pages/company/team/Position'),
    beforeEnter: async (to, from, next) => {
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: '', layout: AppLayout, activeMeny: 'app', authenticate: true, company: true }
  },
]
