export default (axios, checkHeaders) => ({
  getList (data = {}) {
    return axios.get('/baskets', { params: data.filters || {} })
  },
  postOne (data = {}) {
    return axios.post('/basket', data.body, { params: data.filters || {} })
  },
  getOne (data = {}) {
    return axios.get('/basket/' + data.id, { params: data.filters || {} })
  },
  patchOne (data = {}) {
    return axios.patch('/basket/' + data.id, data.body, { params: data.filters || {} })
  },
  deleteOne (data = {}) {
    return axios.delete('/basket/' + data.id, { params: data.filters || {} })
  },
  reloadVision (data = {}) {
    return axios.patch('/basket/' + data.id + '/vision', data.body, { params: data.filters || {} })
  }
})
