export default (axios, checkHeaders) => ({
  getList (data = {}) {
    return axios.get('/clients', { params: data.filters || {} })
  },
  postOne (data = {}) {
    return axios.post('/client', data.body, { params: data.filters || {} })
  },
  getOne (data = {}) {
    return axios.get('/client/' + data.id, { params: data.filters || {} })
  },
  patchOne (data = {}) {
    return axios.patch('/client/' + data.id, data.body, { params: data.filters || {} })
  },
  deleteOne (data = {}) {
    return axios.delete('/client/' + data.id, { params: data.filters || {} })
  },
  csv (data = {}) {
    return axios.post('/clients/csv', data.body, { params: data.filters || {} })
  },
  merge (data = {}) {
    return axios.post('/clients/merge', data.body, { params: data.filters || {} })
  }
})
