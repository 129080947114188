export default (axios, checkHeaders) => ({
  getList (data = {}) {
    return axios.get('/document-numbers', { params: data.filters || {} })
  },
  getOne (data = {}) {
    return axios.get('/document-number/' + data.id, { params: data.filters || {} })
  },
  patchOne (data = {}) {
    return axios.patch('/document-number/' + data.id, data.body, { params: data.filters || {} })
  },
  postOne (data = {}) {
    return axios.post('/document-number', data.body, { params: data.filters || {} })
  }
})
