import store from "../../store"

export const setCurrentCompany = async (to, from, next) => {
  let metaCompany = null
  if (to.meta.company) {
    if (typeof to.meta.company === 'boolean') metaCompany = { auth: to.meta.company }
    if (typeof to.meta.company === 'object') metaCompany = to.meta.company
  }
  if (metaCompany && metaCompany.auth === true && !to.params.company_url) {
    return next({ name: "Error500" })
  }

  if (store.getters.isAuthenticated && (!store.getters.currentPosition || (store.getters.currentPosition && store.getters.currentPosition.company.url !== to.params.company_url))) {
    let foundPosition = store.getters.currentPositions.find(p => p.company.url === to.params.company_url)

    if (!foundPosition) {
      if (metaCompany && metaCompany.auth === true && typeof metaCompany.redirect === "function") {
        return next(metaCompany.redirect(to))
      } else {
        return next({ name: 'Error403' })
      }
    }

    let resp = await store.dispatch('$a_switchPosition', { positionId: foundPosition._id })
    if (resp.err) {
      return next({ name: "Error500" })
    }
  }

  if (store.getters.isAuthenticated && (!store.getters.currentCompany || (store.getters.currentCompany._id !== store.getters.currentPosition.company._id))) {
    let err = await store.dispatch('$a_loadCompanyDatas', { toRoute: to, fromRoute: from })
    if (err) {
      await store.dispatch('$a_logout')
      return next({ name: 'Login' })
    }
  }
}
