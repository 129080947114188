<template>
  <div>
    <div v-if="tags && tags.length > 0">
      <b-badge
        v-for="tag in tags"
        :key="'tag-' + tag._id"
        variant="light"
        class="mr-1 tag mb-1"
        :style="customStyle(tag)"
      >
        {{ tag.name }}
      </b-badge>
    </div>
    <div v-if="(!tags || (tags && tags.length === 0)) && empty">{{ empty }}</div>
  </div>
</template>

<script>
export default {
  name: 'TagList',
  props: {
    tagList: {
      type: Array
    },
    size: {
      type: String,
      default: '1em'
    },
    empty: {
      type: String,
    }
  },
  model: {
    prop: 'tagList',
    event: 'update:tagList'
  },
  computed: {
    tags: {
      get () { return this.tagList },
      set (value) { this.$emit('update:tagList', value) }
    }
  },
  methods: {
    customStyle (tag) {
      let s = ''
      s += ('font-size: ' + this.size + ';')
      s += ('background:' + tag.custom_color + ';')

      return s
    }
  }
}
</script>

<style scoped>
.tag {
  color: #fff;
  vertical-align: middle;
  padding: 5px;
}
a.tag:hover {
 color: #fff;
}
</style>
