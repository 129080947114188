export default (axios, checkHeaders) => ({
  getList (data = {}) {
    return axios.get('/payrolls', { params: data.filters || {} })
  },
  postOne (data = {}) {
    return axios.post('/payroll', data.body, { params: data.filters || {} })
  },
  getOne (data = {}) {
    return axios.get('/payroll/' + data.id, { params: data.filters || {} })
  },
  patchOne (data = {}) {
    return axios.patch('/payroll/' + data.id, data.body, { params: data.filters || {} })
  },
  deleteOne (data = {}) {
    return axios.delete('/payroll/' + data.id, { params: data.filters || {} })
  }
})
