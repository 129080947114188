
let purFilters = {}
try {
  if (localStorage.getItem('purchaseFilters')) {
    purFilters = JSON.parse(localStorage.getItem('purchaseFilters'))
  } else {
    purFilters = { date: 'all', state: 'all', search: '' }
  }
} catch (e) {}

const state = { filters: purFilters }

const getters = {}

const actions = {}

const mutations = {
  purchaseFilters (state, filters) {
    let newFilter = {}
    newFilter.state = filters.state
    newFilter.date = filters.date
    newFilter.minDate = filters.minDate
    newFilter.maxDate = filters.maxDate
    newFilter.search = filters.search
    localStorage.setItem('purchaseFilters', JSON.stringify(newFilter))
    state.filters = { ...newFilter }
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
