import axios from 'axios'
import CryptoJS from 'crypto-js'
import { setInterceptorConfig } from "../axios.service"

import Auth from './auth.apifactory'
import Service from './service.apifactory'
import Test from './test.apifactory'

const axiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/v3.2`,
  timeout: 100000,
  headers: {
    'Access-Control-Allow-Origin': 'Allow',
    'Content-Type': 'application/json'
  }
})
setInterceptorConfig(axiosInstance)

const getSignature = (method, uri, dateTime) => {
  let word = method + uri + dateTime

  return CryptoJS.HmacSHA1(word, process.env.VUE_APP_AUTOSIGNED_API_SECRET)
}

export default {
  // HELPERS
  setAuthorization (token) {
    axiosInstance.defaults.headers.Authorization = 'Bearer ' + token
    delete axiosInstance.defaults.headers['X-ApiKey']
    delete axiosInstance.defaults.headers['X-Signature']
    delete axiosInstance.defaults.headers['X-Datetime']
  },
  removeAuthorization () {
    delete axiosInstance.defaults.headers['Authorization']
    delete axiosInstance.defaults.headers['X-ApiKey']
    delete axiosInstance.defaults.headers['X-Signature']
    delete axiosInstance.defaults.headers['X-Datetime']
  },
  // MODULES
  auth: Auth(axiosInstance, getSignature),
  service: Service(axiosInstance, getSignature),
  test: Test(axiosInstance, getSignature)
}
