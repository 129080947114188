export default (axios, checkHeaders) => ({
  getList (data = {}) {
    return axios.get('/document-models', { params: data.filters || {} })
  },
  postOne (data = {}) {
    return axios.post('/document-model', data.body, { params: data.filters || {} })
  },
  getOne (data = {}) {
    return axios.get('/document-model/' + data.id, { params: data.filters || {} })
  },
  patchOne (data = {}) {
    return axios.patch('/document-model/' + data.id, data.body, { params: data.filters || {} })
  },
  deleteOne (data = {}) {
    return axios.delete('/document-model/' + data.id, { params: data.filters || {} })
  }
})
