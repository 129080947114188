export default (axios, checkHeaders) => ({
  getList (data = {}) {
    return axios.get('/product-categories', { params: data.filters || {} })
  },
  postOne (data = {}) {
    return axios.post('/product-category', data.body, { params: data.filters || {} })
  },
  getOne (data = {}) {
    return axios.get('/product-category/' + data.id, { params: data.filters || {} })
  },
  patchOne (data = {}) {
    return axios.patch('/product-category/' + data.id, data.body, { params: data.filters || {} })
  },
  deleteOne (data = {}) {
    return axios.delete('/product-category/' + data.id, { params: data.filters || {} })
  }
})
