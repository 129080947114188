<template>
  <div ref="selectTags">
    <b-dropdown toggle-class="form-control align-left button-select-tags bg-white" block no-caret menu-class="w-100"  @shown="onShown">
      <template #button-content>
        <div v-if="tags && tags.length > 0" class="text-left text-body font-weight-normal">
          <b-badge
            v-for="tag in tags" :style="customStyle(tag)" class="mr-1 text-white tag" :key="'tag-selected-' + tag._id">
            {{ tag.name }}
          </b-badge>
        </div>
        <div v-else class="text-left text-body font-weight-normal">{{ placeholder }}</div>
      </template>

      <b-dropdown-form @submit.stop.prevent="() => {}">
        <b-form-input
          v-model="suggest"
          :autofocus="autofocusSearch"
          type="search"
          size="sm"
          autocomplete="off"
          placeholder="Rechercher"
          @input="debounceOnChange"
        ></b-form-input>
      </b-dropdown-form>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-text v-if="suggestTags.length > 0">
        <b-badge
          v-for="suggestTag in suggestTags" :key="'suggestTag-' + suggestTag._id" :style="customStyle(suggestTag)" class="mr-1 text-white" href="javascript:void(0)" @click="onSelectTag(suggestTag)">
          {{ suggestTag.name }}
          <b-icon icon="check2" v-if="isInList(suggestTag)" ></b-icon>
        </b-badge>
      </b-dropdown-text>
      <b-dropdown-text v-else>
        Aucun tag trouvé.
      </b-dropdown-text>
    </b-dropdown>
  </div>
</template>

<script>
// Libs & Helpers
import {debounce} from 'debounce'
import api from '../../utils/api_factory'
import to from 'await-to-js'
// Components
import TagList from "../Tag/TagList"

export default {
  name: "SelectTags",
  components: { TagList },
  props: {
    tagsVModel: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: "bottom"
    },
    placeholder: {
      type: String,
      default: "Étiquettes"
    },
    state: {
      type: String,
      default: null
    },
    global: {
      type: Boolean,
      default: true
    },
    resource: {
      type: [String, Array],
      default: null
    }
  },
  model: {
    prop: 'tagsVModel',
    event: 'update:tagsVModel'
  },
  computed: {
    tags: {
      get () { return this.tagsVModel },
      set (value) { this.$emit('update:tagsVModel', value) }
    },
    getStyle () {
      let s = ''

      if (this.placement === 'top') {
        s += 'bottom: 50px;'
      } else {
        s += 'top: 50px;'
      }

      return s
    }
  },
  data () {
    return {
      autofocusSearch: false,
      suggest: '',
      suggestTags: []
    }
  },
  methods: {
    customStyle (tag) {
      let s = ''
      s += ('background:' + tag.custom_color + ';')

      return s
    },
    debounceOnChange: debounce(function (e) {
      this.onSuggestTags()
    }),
    isInList (suggestTag) {
      return this.tags.findIndex(t => t._id === suggestTag._id) >= 0
    },
    onSelectTag (tag) {
      if (!this.tags) this.tags = []

      let findIndex = this.tags.findIndex(t => t._id === tag._id)
      if (findIndex >= 0) {
        this.tags.splice(findIndex, 1)
      } else {
        this.tags.push(tag)
      }

      this.$emit('select', tag)
    },
    onShown () {
      this.suggest = ''
      this.autofocusSearch = true
      this.onSuggestTags()
    },
    onSuggestTags: async function () {
      let payload = { sort: 'name' }
      if (this.suggest && this.suggest !== '') {
        payload.name = this.suggest
      }

      if (this.global) {
        payload.global = this.global
      }

      if (this.resource) {
        payload.resource = this.resource
      }

      let [err, response] = await to(api.company.tag.getList({ filters: payload }))
      if (err) {
        this.suggestTags = []
        return
      }

      this.suggestTags = response.data.tags
    }
  }
}
</script>

<style>
.tag {
  color: #ffffff;
  font-size:0.9rem;
}

.button-select-tags {
  overflow: auto;
}
.button-select-tags::-webkit-scrollbar {
  height: 5px;              /* height of horizontal scrollbar ← You're missing this */
  width: 5px;               /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}
</style>
