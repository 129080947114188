import axios from 'axios'
import {setInterceptorConfig} from "../axios.service"

const axiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/v3.2`,
  timeout: 100000,
  headers: {
    'Access-Control-Allow-Origin': 'Allow',
    'Content-Type': 'application/json',
    'Authorization': null,
  }
})
setInterceptorConfig(axiosInstance)

export default {
  // HELPERS
  setAuthorization (token) {
    axiosInstance.defaults.headers.Authorization = 'Bearer ' + token
  },
  // MODULES
  getCurrentPositionToken (data = {}) {
    return axiosInstance.post('/auth/company', { position: data.position }, { params: data.filters || {} })
  },
  getCurrent () {
    return axiosInstance.get('/user')
  },
  getInvitations (data = {}) {
    return axiosInstance.get('/user/' + data.id + '/invitations', { params: data.filters || {} })
  },
  getNotifications (data = {}) {
    return axiosInstance.get('/user/' + data.id + '/notifications', { params: data.filters || {} })
  },
  getPositions (data = {}) {
    return axiosInstance.get('/user/' + data.id + '/positions', { params: data.filters || {} })
  },
  updateInvitation (data = {}) {
    return axiosInstance.patch('/user/' + data.userId + '/invitation/' + data.id, data.body, { params: data.filters || {} })
  },
  createCompany (data = {}) {
    return axiosInstance.post('/company', data.body, { params: data.filters || {} })
  },
  createPosition (data = {}) {
    return axiosInstance.post('/company/' + data.id + '/position', data.body, { params: data.filters || {} })
  },
  updateUser (data = {}) {
    return axiosInstance.patch('/user/' + data.id, data.body, { params: data.filters || {} })
  }
}
