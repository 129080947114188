export default (axios, checkHeaders) => ({
  getList (data = {}) {
    return axios.get('/delivery-receipts', { params: data.filters || {} })
  },
  postOne (data = {}) {
    return axios.post('/delivery-receipt', data.body, { params: data.filters || {} })
  },
  getOne (data = {}) {
    return axios.get('/delivery-receipt/' + data.id, { params: data.filters || {} })
  },
  patchOne (data = {}) {
    return axios.patch('/delivery-receipt/' + data.id, data.body, { params: data.filters || {} })
  },
  deleteOne (data = {}) {
    return axios.delete('/delivery-receipt/' + data.id, { params: data.filters || {} })
  },
  getHtml (data = {}) {
    checkHeaders(axios, 'GET', '/delivery-receipt/' + data.id + '/html')

    return axios.get('/delivery-receipt/' + data.id + '/html', { params: data.filters || {} })
  },
  postInvoice (data = {}) {
    return axios.post('/delivery-receipt/' + data.id + '/invoice', data.body || {}, { params: data.filters || {} })
  },
})
