import {ifAuthenticated, resolveLoadingApp} from "../utils/middlewares/auth.middleware"
import {setCurrentCompany} from "../utils/middlewares/company.middleware"
import CompanyLayout from '../layouts/subLayout/CompanyLayout'

export default [
  {
    path: '/error/500',
    name: 'Error500',
    component: () => import('../pages/errors/500'),
    beforeEnter: async (to, from, next) => {
      await resolveLoadingApp(to, from, next)
    },
    meta: { noNavigation: true }
  },
  {
    path: '/error/403',
    name: 'Error403',
    component: () => import('../pages/errors/403'),
    beforeEnter: async (to, from, next) => {
      await resolveLoadingApp(to, from, next)
    },
    meta: { noNavigation: true }
  },
  {
    path: '/error/404',
    name: 'Error404',
    component: () => import('../pages/errors/404'),
    beforeEnter: async (to, from, next) => {
      await resolveLoadingApp(to, from, next)
    },
    meta: { noNavigation: true }
  },
  {
    path: '/error/lost-connection',
    name: 'ErrorLostConnection',
    component: () => import('../pages/errors/LostConnection'),
    beforeEnter: async (to, from, next) => {
      await resolveLoadingApp(to, from, next)
    },
    meta: { noNavigation: true }
  },
  {
    path: '/:company_url/error/403',
    name: 'CompanyError403',
    component: () => import('../pages/errors/403Company'),
    beforeEnter: async (to, from, next) => {
      await ifAuthenticated(to, from, next)
      await setCurrentCompany(to, from, next)
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'exports.menu', layout: CompanyLayout, activeMenu: "app", noNavigation: true }
  },
]
