export default (axios, checkHeaders) => ({
  getList (data = {}) {
    return axios.get('/interlocutors', { params: data.filters || {} })
  },
  postOne (data = {}) {
    return axios.post('/interlocutor', data.body, { params: data.filters || {} })
  },
  getOne (data = {}) {
    return axios.get('/interlocutor/' + data.id, { params: data.filters || {} })
  },
  patchOne (data = {}) {
    return axios.patch('/interlocutor/' + data.id, data.body, { params: data.filters || {} })
  },
  deleteOne (data = {}) {
    return axios.delete('/interlocutor/' + data.id, { params: data.filters || {} })
  }
})
