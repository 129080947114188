export default (axios, checkHeaders) => ({
  getList (data = {}) {
    return axios.get('/purchases', { params: data.filters || {} })
  },
  postOne (data = {}) {
    return axios.post('/purchase', data.body, { params: data.filters || {} })
  },
  getOne (data = {}) {
    return axios.get('/purchase/' + data.id, { params: data.filters || {} })
  },
  patchOne (data = {}) {
    return axios.patch('/purchase/' + data.id, data.body, { params: data.filters || {} })
  },
  deleteOne (data = {}) {
    return axios.delete('/purchase/' + data.id, { params: data.filters || {} })
  },
  stats (data = {}) {
    return axios.get('/purchases/stats', { params: data.filters || {} })
  },
  postCredit (data = {}) {
    return axios.post('/purchase/' + data.id + '/credit', data.body, { params: data.filters || {} })
  },
  postFile (data = {}) {
    return axios.post('/purchase-file', data.body, { params: data.filters || {} })
  }
})
