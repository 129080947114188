import {resolveLoadingApp} from "@/utils/middlewares/auth.middleware"
import AppLayout from "@/layouts/AppLayout"
import store from "@/store"

import safeCategories from "@/pages/company/safe/subComponents/SafeCategories"
import safeRHCategories from "@/pages/company/safe/safeRHCategories"

const beforeEnterCheckerAccessSafe = function (to, from, next, redirect = { name: 'Error403' }) {
  let position = store.getters.currentPosition
  if (position) {
    let access = store.getters.currentRights.CREATE_PAYROLL || store.getters.currentRights.SEE_ALL_PAYROLLS
    if (!access) return next(redirect)
  }

  next()
}

const beforeEnterCheckerAccessMySafe = function (to, from, next, redirect = { name: 'Error403' }) {
  let position = store.getters.currentPosition
  if (position) {
    let access = store.getters.currentRights.SEE_ALL_PAYROLLS || (store.getters.currentRights.SEE_MY_PAYROLLS && position._id === to.params.position)
    if (!access) return next(redirect)
  }

  next()
}

export default [
  // Ancienne route
  {
    path: '/:company_url/payrolls',
    redirect: to => {
      return { name: 'Safe', params: { company_url: to.params.company_url } }
    }
  },
  // Ancienne route
  {
    path: '/:company_url/payrolls/position/:id',
    redirect: to => {
      return { name: 'MyPayrolls', params: { company_url: to.params.company_url, position: to.params.id } }
    }
  },
  {
    path: '/:company_url/safe/file/:file_type',
    name: 'SafeRedirectFromFileType',
    redirect: to => {
      let params = { company_url: to.params.company_url }
      if (to.params.position) params.position = to.params.position

      let category = safeCategories.find(category => category.file_type.includes(to.params.file_type))
      if (category) {
        params.category = category.routeName
        return { name: category.routeName, params }
      }

      let categoryRH = safeRHCategories.find(category => category.file_type.includes(to.params.file_type))
      if (categoryRH) {
        params.category = categoryRH.routeName
        return { name: categoryRH.routeName, params }
      }

      return { name: 'Error403', params }
    }
  },
  {
    path: '/:company_url/safe',
    component: () => import('../pages/company/safe/Safe.vue'),
    children: [
      {
        path: '',
        name: 'Safe',
        component: () => import('../pages/company/safe/subComponents/SafeCategories.vue'),
        beforeEnter: async (to, from, next) => {
          let access = store.getters.currentRights.CREATE_PAYROLL || store.getters.currentRights.SEE_ALL_PAYROLLS
          if (!access) {
            return next({ name: 'SafePosition', params: { company_url: store.getters.currentCompany.url, position: store.getters.currentPosition._id } })
          }

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'documents.safe_title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true },
      },
      {
        path: ':category',
        name: 'SafeCategory',
        component: () => import('../pages/company/safe/subComponents/SafeCategories.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerAccessSafe(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'documents.safe_title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true },
      },
      {
        path: ':category/files',
        name: 'SafeCategoryFiles',
        component: () => import('../pages/company/safe/subComponents/SafeFileList.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerAccessSafe(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'documents.safe_title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true },
      },
      {
        path: ':category/positions',
        name: 'SafeCategoryPositions',
        component: () => import('../pages/company/safe/subComponents/SafePositions.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerAccessSafe(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'documents.safe_title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true },
      },
      {
        path: ':category/:position/files',
        name: 'SafeCategoryPositionFiles',
        component: () => import('../pages/company/safe/subComponents/SafeFileList.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerAccessSafe(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'documents.safe_title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true },
      },
      {
        path: ':category/add',
        name: 'SafeCreate',
        component: () => import('../pages/company/safe/subComponents/SafeCreateFile.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerAccessSafe(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'documents.safe_title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true },
      },
      {
        path: ':category/update/:id',
        name: 'SafeUpdate',
        component: () => import('../pages/company/safe/subComponents/SafeUpdateFile.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerAccessSafe(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'documents.safe_title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true },
      },
      {
        path: ':category/:position/add',
        name: 'SafePositionCreate',
        component: () => import('../pages/company/safe/subComponents/SafeCreateFile.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerAccessSafe(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'documents.safe_title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true },
      },
      {
        path: ':category/:position/update/:id',
        name: 'SafePositionUpdate',
        component: () => import('../pages/company/safe/subComponents/SafeUpdateFile.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerAccessSafe(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'documents.safe_title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true },
      },
    ]
  },
  {
    path: '/:company_url/safe-rh',
    component: () => import('../pages/company/safe/SafeRH.vue'),
    children: [
      {
        path: '',
        name: 'SafeRH',
        component: () => import('../pages/company/safe/subComponents/SafeCategories.vue'),
        beforeEnter: async (to, from, next) => {
          let access = store.getters.currentRights.CREATE_PAYROLL || store.getters.currentRights.SEE_ALL_PAYROLLS
          if (!access) {
            return next({ name: 'SafePosition', params: { company_url: store.getters.currentCompany.url, position: store.getters.currentPosition._id } })
          }

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'documents.safe_title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true },
      },
      {
        path: 'payrolls/:position/files',
        name: 'PositionPayrolls',
        component: () => import('../pages/company/payroll/PayrollsPosition'),
        beforeEnter: async (to, from, next) => {
          const seeMyPayrolls = store.getters.currentRights.SEE_MY_PAYROLLS
          const seeAllPayRolls = store.getters.currentRights.SEE_ALL_PAYROLLS

          const currentPositionId = store.getters.currentPosition._id

          if (!seeAllPayRolls) {
            if (seeMyPayrolls && currentPositionId === to.params.position) {
              return next({ name: 'MyPayrolls', params: { company_url: store.getters.currentCompany.url, position: store.getters.currentPosition._id } })
            }

            return next({ name: 'Error403' })
          }

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'payrolls.title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
      },
      {
        path: 'payrolls/:position/create',
        name: 'PayrollsCreate',
        component: () => import('../pages/company/payroll/PayrollCreate'),
        beforeEnter: async (to, from, next) => {
          let access = store.getters.currentRights.CREATE_PAYROLL
          if (!access) {
            return next({ name: 'Error403' })
          }

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'payrolls.add_title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
      },
      {
        path: 'payrolls/update/:id',
        name: 'PayrollsUpdate',
        component: () => import('../pages/company/payroll/PayrollUpdate'),
        beforeEnter: async (to, from, next) => {
          let access = store.getters.currentRights.UPDATE_PAYROLL
          if (!access) {
            return next({ name: 'Error403' })
          }

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'payrolls.update_title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
      },
      {
        path: ':category',
        name: 'SafeRHCategory',
        component: () => import('../pages/company/safe/subComponents/SafeCategories.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerAccessSafe(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'documents.safe_title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true },
      },
      {
        path: ':category/files',
        name: 'SafeRHCategoryFiles',
        component: () => import('../pages/company/safe/subComponents/SafeFileList.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerAccessSafe(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'documents.safe_title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true },
      },
      {
        path: ':category/positions',
        name: 'SafeRHCategoryPositions',
        component: () => import('../pages/company/safe/subComponents/SafePositions.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerAccessSafe(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'documents.safe_title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true },
      },
      {
        path: ':category/:position/files',
        name: 'SafeRHCategoryPositionFiles',
        component: () => import('../pages/company/safe/subComponents/SafeFileList.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerAccessSafe(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'documents.safe_title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true },
      },
      {
        path: ':category/add',
        name: 'SafeRHCreate',
        component: () => import('../pages/company/safe/subComponents/SafeCreateFile.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerAccessSafe(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'documents.safe_title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true },
      },
      {
        path: ':category/update/:id',
        name: 'SafeRHUpdate',
        component: () => import('../pages/company/safe/subComponents/SafeUpdateFile.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerAccessSafe(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'documents.safe_title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true },
      },
      {
        path: ':category/:position/add',
        name: 'SafeRHPositionCreate',
        component: () => import('../pages/company/safe/subComponents/SafeCreateFile.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerAccessSafe(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'documents.safe_title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true },
      },
      {
        path: ':category/:position/update/:id',
        name: 'SafeRHPositionUpdate',
        component: () => import('../pages/company/safe/subComponents/SafeUpdateFile.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerAccessSafe(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'documents.safe_title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true },
      },
    ]
  },
  {
    path: '/:company_url/safe-position/:position',
    component: () => import('../pages/company/safe/SafePosition'),
    children: [
      {
        path: 'payrolls/files',
        name: 'MyPayrolls',
        component: () => import('../pages/company/payroll/PayrollsPosition'),
        beforeEnter: async (to, from, next) => {
          const seeMyPayrolls = store.getters.currentRights.SEE_MY_PAYROLLS
          const seeAllPayRolls = store.getters.currentRights.SEE_ALL_PAYROLLS

          const currentPositionId = store.getters.currentPosition._id

          if (!((seeMyPayrolls && to.params.position === currentPositionId) || (seeAllPayRolls && to.params.position !== currentPositionId))) {
            return next({ name: 'Error403' })
          }

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'payrolls.title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
      },
      {
        path: '',
        name: 'SafePosition',
        component: () => import('../pages/company/safe/subComponents/SafeCategories.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerAccessMySafe(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'documents.safe_title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true },
      },
      {
        path: ':category',
        name: 'SafePositionCategory',
        component: () => import('../pages/company/safe/subComponents/SafeCategories.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerAccessMySafe(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'documents.safe_title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true },
      },
      {
        path: ':category/files',
        name: 'SafePositionCategoryFiles',
        component: () => import('../pages/company/safe/subComponents/SafeFileList.vue'),
        beforeEnter: async (to, from, next) => {
          await beforeEnterCheckerAccessMySafe(to, from, next)

          await resolveLoadingApp(to, from, next)
        },
        meta: { title: 'documents.safe_title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true },
      },
    ]
  }
]
