export default (axios, checkHeaders) => ({
  getDefaultList (data = {}) {
    return axios.get('/mail-models/default', { params: data.filters || {} })
  },
  getList (data = {}) {
    return axios.get('/mail-models', { params: data.filters || {} })
  },
  postOne (data = {}) {
    return axios.post('/mail-model', data.body, { params: data.filters || {} })
  },
  getOne (data = {}) {
    return axios.get('/mail-model/' + data.id, { params: data.filters || {} })
  },
  patchOne (data = {}) {
    return axios.patch('/mail-model/' + data.id, data.body, { params: data.filters || {} })
  },
  deleteOne (data = {}) {
    return axios.delete('/mail-model/' + data.id, { params: data.filters || {} })
  }
})
