const currencyFormatter = require('currency-formatter')

const price = function (value, currency = 'EUR', lang = 'fr-FR') {
  return currencyFormatter.format(value, { code: currency, locale: lang, decimalDigits: 2 })
}

const priceHT = function (valueTTC, tva = 0, currency = 'EUR', lang = 'fr-FR') {
  return currencyFormatter.format(valueTTC / (1 + tva / 100), { code: currency, locale: lang, decimalDigits: 2 })
}

const priceTTC = function (valueHT, tva = 0, currency = 'EUR', lang = 'fr-FR') {
  return currencyFormatter.format(valueHT * (1 + tva / 100), { code: currency, locale: lang, decimalDigits: 2 })
}

export default { price, priceHT, priceTTC }
