import AppLayout from '../layouts/AppLayout'
import {resolveLoadingApp} from "../utils/middlewares/auth.middleware"
import store from "@/store";

const beforeEnterCheckerSeeProducts = function (to, from, next, redirect = { name: 'Error403' }) {
  let position = store.getters.currentPosition
  if (position) {
    let seeMy = store.getters.currentRights.SEE_MY_PRODUCTS
    let seeTeam = store.getters.currentRights.SEE_TEAM_PRODUCTS
    let seeAll = store.getters.currentRights.SEE_ALL_PRODUCTS

    if (!seeMy && !seeTeam && !seeAll) return next(redirect)
  }

  next()
}

const beforeEnterCheckerAddProduct = function (to, from, next, redirect = { name: 'Error403' }) {
  let position = store.getters.currentPosition
  if (position) {
    if (!store.getters.currentRights.CREATE_PRODUCT && !store.getters.currentRights.UPDATE_PRODUCT) return next(redirect)
  }

  next()
}

export default [
  {
    path: '/:company_url/products',
    name: 'ProductsList',
    component: () => import('../pages/company/product/ProductsList'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerSeeProducts(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'products.title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/products/categories',
    name: 'ProductCategoriesList',
    component: () => import('../pages/company/productCategory/ProductCategoryList'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerSeeProducts(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'product_categories.title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/product/create',
    name: 'ProductCreate',
    component: () => import('../pages/company/product/ProductForm'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddProduct(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'products.add', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/product/update/:id',
    name: 'ProductUpdate',
    component: () => import('../pages/company/product/ProductForm'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddProduct(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'products.update', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/product/show/:id',
    name: 'ProductShow',
    component: () => import('../pages/company/product/Product'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerSeeProducts(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'products.show', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/product/category/create',
    name: 'ProductCategoryCreate',
    component: () => import('../pages/company/productCategory/ProductCategoryCreate'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddProduct(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'product_categories.add', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/product/category/update/:id',
    name: 'ProductCategoryUpdate',
    component: () => import('../pages/company/productCategory/ProductCategoryUpdate'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddProduct(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'product_categories.update', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/products/import',
    name: 'ProductsImport',
    component: () => import('../pages/company/product/ProductsImport'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddProduct(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'products.import_title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/product/:productId/variant/:variantId',
    name: 'ProductVariantShow',
    component: () => import('../pages/company/product/ProductVariant'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerSeeProducts(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'products.show', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/product/:productId/variant/:variantId/edit',
    name: 'ProductVariantEdit',
    component: () => import('../pages/company/product/ProductVariantForm'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddProduct(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: '', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/products/counts',
    name: 'ProductCountsList',
    component: () => import('../pages/company/productCount/ProductCountList'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerSeeProducts(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'product_counts.title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/product/count/create',
    name: 'ProductCountCreate',
    component: () => import('../pages/company/productCount/ProductCountCreate'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddProduct(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'product_counts.add', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/product/count/update/:id',
    name: 'ProductCountEdit',
    component: () => import('../pages/company/productCount/ProductCountUpdate'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddProduct(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'product_counts.update', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
]
