<template>
  <section class="LayoutDefault">
    <b-row class="cover-full">
      <div class="LayoutDefault__panel col-lg-4 left">
        <div class="inner-content">
          <img class="logo-home" src="@/assets/img/logo/wuro-full.svg" alt="Logo Wuro" @click="$router.push('/login')">
          <h1 class="header-text" v-html="panelText"></h1>
        </div>
        <img class="illu-center" src="@/assets/img/wuro-gerer-entreprise.png" alt="Inscription">
      </div>
      <main class="LayoutDefault__main col-lg-8 col-sm-12 right">
        <slot></slot>
      </main>
    </b-row>
  </section>
</template>

<script>
export default {
  name: `LayoutDefault`,
  data () {
    return {
      panelText: "<span class='wuro-highlight'>Gérer votre entreprise</span> n'a jamais été aussi simple."
    }
  },
  methods: {
  }
}
</script>

<style lang="scss">
.cover-full {
  display: flex;
  height: 100vh;
}

.top-right-link {
  position: absolute;
  top: 20px;
  right: 55px;
}

.left {
  justify-content : center;
  align-items : center;
  background-color:#3d231b;
  height : 100%;
  color : #FFF;
}
.right{
  justify-content : center;
  align-items : center;
  background-color:#FFF;
  color : #3d231b;
}

.LayoutDefault {
  padding-bottom: 0;
}

.LayoutDefault__panel {
  background: url(/src/assets/img/plant.svg) no-repeat bottom right,
  url(/src/assets/img/lines.svg) no-repeat bottom,
  linear-gradient(#F0F9EB,#BEF2EE);
  background-size: 30%, 100%, 80%;

  .inner-content {
    padding: 40px 45px 0 45px;

    .logo-home {
      max-width: 250px;
    }

    .header-text {
      font-size: 2.6em !important;
      padding: 8px;

      span.wuro-highlight {
        color: #52b2b2;
      }
    }
  }

  .illu-center {
    width: 100%;
    max-width: 570px
  }
}

.LayoutDefault__main {
  display: flex;
  justify-content: center;
  align-items: center;
}

#installBtn {
  width: 290px;
  max-width: 290px;
  left: calc(50% - 145px)
}

@media only screen and (max-width: 1595px) {
  .LayoutDefault__panel {
    .inner-content {
      .header-text {
        font-size: 2em !important;
      }
    }

    .illu-center {
      max-width: 370px
    }
  }
}

@media only screen and (max-width: 995px) {
  .LayoutDefault__panel {
    display: none;
  }
  #installBtn {
    width: 100vw;
    min-width: 100%;
    left: 0;
    border-radius: 0 !important;
  }
}
</style>
