export default (axios, getSignature) => ({
  register (data = {}) {
    let dateTime = new Date().getTime()

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('POST', '/auth/register', dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.post('/auth/register', data.body, { params: data.filters })
  },
  login (data = {}) {
    let dateTime = new Date().getTime()

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('POST', '/auth/login', dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.post('/auth/login', data.body, { params: data.filters })
  },
  google (data = {}) {
    let dateTime = new Date().getTime()

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('POST', '/auth/google', dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.post('/auth/google', data.body, { params: data.filters })
  },
  linkedin (data = {}) {
    let dateTime = new Date().getTime()

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('POST', '/auth/linkedin', dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.post('/auth/linkedin', data.body, { params: data.filters })
  },
  confirm (data = {}) {
    return axios.patch('/auth/register/confirm', data.body, { params: data.filters })
  },
  passwordReset (data = {}) {
    let dateTime = new Date().getTime()

    axios.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axios.defaults.headers['X-Signature'] = getSignature('POST', '/auth/password/reset', dateTime)
    axios.defaults.headers['X-Datetime'] = dateTime

    return axios.post('/auth/password/reset', data.body, { params: data.filters })
  },
  passwordConfirm (data = {}) {
    return axios.patch('/auth/password/confirm', data.body, { params: data.filters })
  },
  getInvitation (data = {}) {
    return axios.get('/auth/invitation/' + data.id, data.body, { params: data.filters })
  },
  confirmInvitation (data = {}) {
    return axios.patch('/auth/invitation/' + data.id + '/confirm', { state: "confirmed" }, { params: data.filters })
  }
})
