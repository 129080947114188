export default (axios, checkHeaders) => ({
  getList (data = {}) {
    return axios.get('/purchase-categories', { params: data.filters || {} })
  },
  postOne (data = {}) {
    return axios.post('/purchase-category', data.body, { params: data.filters || {} })
  },
  getOne (data = {}) {
    return axios.get('/purchase-category/' + data.id, { params: data.filters || {} })
  },
  patchOne (data = {}) {
    return axios.patch('/purchase-category/' + data.id, data.body, { params: data.filters || {} })
  },
  deleteOne (data = {}) {
    return axios.delete('/purchase-category/' + data.id, { params: data.filters || {} })
  }
})
