import AppLayout from '../layouts/AppLayout'
import {resolveLoadingApp} from "@/utils/middlewares/auth.middleware"
import store from "@/store"

const beforeEnterCheckerManageBankReconciliation = function (to, from, next, redirect = { name: 'Error403' }) {
  let position = store.getters.currentPosition
  if (position) {
    let checkManage = store.getters.currentRights.MANAGE_BANK_RECONCILIATION

    if (!checkManage) return next(redirect)
  }

  next()
}

export default [
  {
    path: 'bank-reconciliation-start',
    name: 'BankReconciliationStart',
    component: () => import('../pages/company/bankReconciliation/BankReconciliationStart.vue'),
    beforeEnter: async (to, from, next) => {
      if (!store.getters.currentCompany.options.fec) return next({ name: 'Error403' })

      await beforeEnterCheckerManageBankReconciliation(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'home.title', layout: AppLayout, activeMenu: 'app', authenticate: true, company: true }
  },
  {
    path: 'bank-reconciliations',
    name: 'BankReconciliationList',
    component: () => import('../pages/company/bankReconciliation/BankReconciliationList.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerManageBankReconciliation(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'home.title', layout: AppLayout, activeMenu: 'app', authenticate: true, company: true }
  },
  {
    path: 'bank-reconciliation/:id',
    name: 'BankReconciliation',
    component: () => import('../pages/company/bankReconciliation/BankReconciliation.vue'),
    beforeEnter: async (to, from, next) => {
      if (!store.getters.currentCompany.options.fec) return next({ name: 'Error403' })

      await beforeEnterCheckerManageBankReconciliation(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'home.title', layout: AppLayout, activeMenu: 'app', authenticate: true, company: true }
  },
  // COUNT
  {
    path: '/:company_url/bank-reconciliation-counts',
    name: 'BankReconciliationCountsList',
    component: () => import('../pages/company/bankReconciliationCount/BankReconciliationCountList'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerManageBankReconciliation(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'product_counts.title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/bank-reconciliation-count/create',
    name: 'BankReconciliationCountCreate',
    component: () => import('../pages/company/bankReconciliationCount/BankReconciliationCountCreate'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerManageBankReconciliation(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'product_counts.add', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/bank-reconciliation-count/update/:id',
    name: 'BankReconciliationCountEdit',
    component: () => import('../pages/company/bankReconciliationCount/BankReconciliationCountUpdate'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerManageBankReconciliation(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'product_counts.update', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  }
]
