import { render, staticRenderFns } from "./TagList.vue?vue&type=template&id=55a5f49a&scoped=true"
import script from "./TagList.vue?vue&type=script&lang=js"
export * from "./TagList.vue?vue&type=script&lang=js"
import style0 from "./TagList.vue?vue&type=style&index=0&id=55a5f49a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55a5f49a",
  null
  
)

export default component.exports