<template>
  <div class="position-relative">
    <slot></slot>

    <div class="alert-icon" :style="position" v-if="show" v-b-tooltip.hover="tooltip">
      <div class="parent"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotificationIcon',
  props: {
    showVModel: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: ""
    },
    position: {
      type: String
    },
    color: {
      type: String,
      default: '#f5910c'
    },
    radius: {
      type: String,
      default: '100%'
    },
  },
  model: {
    prop: 'showVModel',
    event: 'update:showVModel'
  },
  computed: {
    show: {
      get () { return this.showVModel },
      set (value) { this.$emit('update:showVModel', value) }
    },
    spinnerStyle () {
      return {
        height: '15px',
        width: '15px',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: this.color + ' ' + this.color + ' transparent',
        borderRadius: this.radius,
        background: 'transparent'
      }
    }
  }
}
</script>

<style>
.alert-icon {
  position: absolute;
}

.parent{
  width:20px;
  height:20px;
  background:#f5910c;
  margin:auto;
  border-radius:100%;
  margin-top:30px;
  position:relative;
  cursor:pointer;
}
@keyframes breathe{
  0%{transform:scale(1)}
  50%{transform:scale(0.5)}
  100%{transform:scale(1)}
}
@keyframes breathe2{
  0%{transform:scale(1)}
  50%{transform:scale(1.2)}
  100%{transform:scale(1)}
}
.parent:before{
  content:"";
  position:absolute;
  top:-12.5px;
  left:-12.5px;
  z-index:2;
  width:45px;
  height:45px;
  background:#f5910c;
  opacity:0.3;
  border-radius:100%;
  animation:breathe2 2s infinite;
}
.parent:after{
  content:"";
  position:absolute;
  top:-22.5px;
  left:-22.5px;
  z-index:3;
  width:65px;
  height:65px;
  background:#f5910c;
  opacity:0.2;
  border-radius:100%;
  animation:breathe 2s infinite;
}
.parent > span{
  color:#fff;
  font-weight:bold;
  font-size:12.5px;
  position:absolute;
  z-index:4;
  top:3px;
  left:4px;
}
</style>
