
export const capitalize = function (value) {
  if (!value) return ''

  value = value.toString().toLowerCase()
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const fullName = function (item) {
  if (!item || (item && typeof item !== 'object')) return ''

  let fullName = ''

  let firstName = item.first_name || item.firstName
  if (firstName && firstName.length > 0) fullName += capitalize(firstName)

  let lastName = item.last_name || item.lastName
  if (lastName && lastName.length > 0) {
    if (fullName !== '') fullName += ' '
    fullName += lastName.toUpperCase()
  }

  if (fullName === '' && item.name && item.name.length > 0) return capitalize(item.name)

  return fullName
}

export const initials = function (item) {
  if (!item || (item && typeof item !== 'object')) return ''

  let fullName = ''

  let firstName = item.first_name || item.firstName
  if (firstName && firstName.length > 0) fullName += firstName.charAt(0).toUpperCase()

  let lastName = item.last_name || item.lastName
  if (lastName && lastName.length > 0) fullName += lastName.charAt(0).toUpperCase()

  if (fullName === '' && item.name) return item.name.charAt(0).toUpperCase()

  return fullName
}

export const uppercase = function (item) {
  if (!item) return ''

  return item.toUpperCase()
}

export const lowercase = function (item) {
  if (!item) return ''

  return item.toLowerCase()
}

export default { fullName, initials, capitalize, uppercase, lowercase }
