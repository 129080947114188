const { RIGHTS } = require('./rights.service')

export const MENU = [
  {
    tooltip: 'documents.invoices_menu',
    routeName: 'InvoicesList',
    icon: 'ion-ios-document',
    needRights: [RIGHTS.SEE_MY_INVOICES, RIGHTS.SEE_TEAM_INVOICES, RIGHTS.SEE_ALL_INVOICES],
    orRight: true,
    group: 'invoices',
    image: 'factures',
    commercial: true
  },
  {
    tooltip: 'documents.quotes_menu',
    routeName: 'QuotesList',
    icon: 'ion-ios-copy',
    needRights: [RIGHTS.SEE_MY_QUOTES, RIGHTS.SEE_TEAM_QUOTES, RIGHTS.SEE_ALL_QUOTES],
    orRight: true,
    group: 'quotes',
    image: 'devis',
    commercial: true
  },
  {
    tooltip: 'clts.menu',
    routeName: 'ClientList',
    query: { tags: "clients" },
    icon: 'ion-ios-contacts',
    needRights: [RIGHTS.SEE_MY_CONTACTS, RIGHTS.SEE_TEAM_CONTACTS, RIGHTS.SEE_ALL_CONTACTS],
    orRight: true,
    group: 'clients',
    image: 'clients',
    commercial: true
  },
  {
    tooltip: 'contacts.menu',
    special: 'contact',
    imageSrc: process.env.VUE_APP_LOCAL_URL + '/img/personnage-wuro2.svg',
    group: 'clients',
    submenu: [
      {
        label: 'Clients',
        routeName: 'ClientList',
        query: { tags: "clients" },
      },
      {
        label: 'Prospects',
        routeName: 'ClientList',
        query: { tags: "prospects" },
      },
      {
        label: 'Fournisseurs',
        routeName: 'ClientList',
        query: { tags: "fournisseurs" },
      }
    ],
    routeName: 'ClientList',
    icon: 'ion-ios-contacts',
    needRights: [RIGHTS.SEE_MY_CONTACTS, RIGHTS.SEE_TEAM_CONTACTS, RIGHTS.SEE_ALL_CONTACTS],
    orRight: true,
    image: 'clients',
    commercial: false
  },
  {
    tooltip: 'safe-deposit.menu',
    routeName: "Safe",
    image: 'coffre-fort',
    group: 'safe',
    needRights: [RIGHTS.SEE_MY_PAYROLLS, RIGHTS.SEE_ALL_PAYROLLS],
    orRight: true,
  },
  {
    tooltip: 'conges.menu',
    routeName: 'Absences',
    image: 'absences',
    group: 'absences',
    needRights: [RIGHTS.SEE_MY_ABSENCES, RIGHTS.SEE_MY_CALENDAR_EVENTS],
    orRight: true,
  },
  {
    tooltip: 'purchases.menu',
    routeName: "PurchasesList",
    icon: 'ion-ios-cart',
    needRights: [RIGHTS.SEE_ALL_PURCHASES, RIGHTS.SEE_MY_BASKETS],
    group: 'purchases',
    image: 'achats',
    orRight: true
  },
  {
    tooltip: 'exports.menu',
    routeName: 'ExportSells',
    icon: 'ion-ios-download',
    needRights: [RIGHTS.MANAGE_EXPORTS],
    group: 'exports',
    image: 'export'
  },
  {
    tooltip: 'products.menu',
    routeName: 'ProductsList',
    icon: 'ion-ios-albums',
    needRights: [RIGHTS.SEE_MY_PRODUCTS, RIGHTS.SEE_TEAM_PRODUCTS, RIGHTS.SEE_ALL_PRODUCTS],
    orRight: true,
    image: 'products',
    group: 'products',
    commercial: true
  },
  {
    tooltip: 'statistics.menu',
    special: 'statistics',
    imageSrc: process.env.VUE_APP_LOCAL_URL + '/img/statistiques.svg',
    submenu: [{
      label: 'Recettes',
      routeName: "SellsStatistics",
      needRights: [RIGHTS.SEE_MY_INVOICES, RIGHTS.SEE_TEAM_INVOICES, RIGHTS.SEE_ALL_INVOICES],
      orRight: true,
    },
    {
      label: 'Dépenses',
      routeName: "PurchasesStatistics",
      needRights: [RIGHTS.SEE_ALL_PURCHASES],
    },
    {
      label: 'Produits',
      routeName: "ProductsStatistics",
      needRights: [RIGHTS.SEE_MY_PRODUCTS, RIGHTS.SEE_TEAM_PRODUCTS, RIGHTS.SEE_ALL_PRODUCTS],
      orRight: true,
    }],
    routeName: "Statistics",
    group: 'statistics',
    image: 'statistiques',
    needRights: [RIGHTS.MANAGE_STATS]
  }
]

export const MENU_COMMERCIAL = MENU.filter(m => m.commercial)

export const MENU_RH = MENU.filter(m => !m.commercial)
