<template>
  <div class="d-inline-block position-avatar position-relative" :class="{'birthday': position.isBirthday}">
    <b-avatar :src="position.avatar"
              :text="initials(position)"
              variant="light"
              :size="size"
              :class="{ 'inactive': position.state === 'inactive' }"
              href="#"
              @click="() => $router.push({name: 'Position', params: { company_url: currentCompany.url, id: position._id}})"
              :badge="(currentRights.SEE_ALL_ABSENCES || currentRights.MANAGE_ABSENCES)  && position && position.isSalaried && position.state === 'active' && position.isHere !== undefined"
              :badge-variant="position.isHere ? 'success' : 'light'"
    ></b-avatar>
  </div>
</template>

<script>
export default {
  name: 'PositionAvatar',
  props: {
    position: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: "40"
    },
    selectPosition: {
      type: Function,
      default: () => {}
    },
  }
}
</script>
