import {resolveLoadingApp} from "../utils/middlewares/auth.middleware"
import store from '../store'
import AppLayout from '../layouts/AppLayout'

const beforeEnterCheckerManageCompany = function (to, from, next) {
  let position = store.getters.currentPosition
  if (position) {
    if (!store.getters.currentRights.MANAGE_COMPANY) return next({ name: 'Error403' })
  }

  next()
}

export default [
  {
    path: '/:company_url/subscription/my-offer',
    name: 'SubscriptionMyOffer',
    component: () => import('../pages/company/subscription/MyOffer.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerManageCompany(to, from, next)
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'version.subscription', layout: AppLayout, activeMenu: 'subscription', authenticate: true, company: true }
  },
  {
    path: '/:company_url/subscription/my-emails',
    name: 'SubscriptionMyEmails',
    component: () => import('../pages/company/subscription/MyEmails.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerManageCompany(to, from, next)
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'version.subscription', layout: AppLayout, activeMenu: 'subscription', authenticate: true, company: true }
  },
  {
    path: '/:company_url/subscription/invoices',
    name: 'VersionsInvoices',
    component: () => import('../pages/company/subscription/MySubscriptionInvoices.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerManageCompany(to, from, next)
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'version.invoices.title', layout: AppLayout, activeMenu: 'subscription', authenticate: true, company: true }
  },
  {
    path: '/:company_url/subscription/:version_id',
    name: 'SubscriptionForm',
    component: () => import('../pages/company/subscription/SubscriptionForm.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerManageCompany(to, from, next)
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'version.subscription', layout: AppLayout, activeMenu: 'subscription', authenticate: true, company: true }
  },
  {
    path: '/:company_url/subscription',
    name: 'Subscription',
    component: () => import('../pages/company/subscription/Subscriptions.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerManageCompany(to, from, next)
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'version.subscription', layout: AppLayout, activeMenu: 'subscription', authenticate: true, company: true }
  },
  {
    path: '/:company_url/subscription/order/:id',
    name: 'Order',
    component: () => import('../pages/company/subscription/Order.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerManageCompany(to, from, next)
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'order.title', layout: AppLayout, activeMenu: 'subscription', authenticate: true, company: true }
  },
]
