import AppLayout from '../layouts/AppLayout'
import {resolveLoadingApp} from "@/utils/middlewares/auth.middleware"
import store from "@/store"

const beforeEnterChecker = function (to, from, next) {
  let position = store.getters.currentPosition
  if (position) {
    if (!(store.getters.currentRights.MANAGE_EXPORTS && (
      store.getters.currentRights.SEE_MY_INVOICES ||
      store.getters.currentRights.SEE_TEAM_INVOICES ||
      store.getters.currentRights.SEE_ALL_INVOICES ||
      store.getters.currentRights.SEE_ALL_PURCHASES ||
      store.getters.currentRights.SEE_MY_PRODUCTS ||
      store.getters.currentRights.SEE_TEAM_PRODUCTS ||
      store.getters.currentRights.SEE_ALL_PRODUCTS ||
      store.getters.currentRights.SEE_MY_CONTACTS ||
      store.getters.currentRights.SEE_TEAM_CONTACTS ||
      store.getters.currentRights.SEE_ALL_CONTACTS ||
      store.getters.currentRights.SEE_MY_ABSENCES ||
      store.getters.currentRights.SEE_MY_CALENDAR_EVENTS ||
      store.getters.currentRights.SEE_TEAM_CONTACTS ||
      store.getters.currentRights.SEE_ALL_CONTACTS
    ))) return next({ name: "Error403" })
  }

  next()
}

export default [
  {
    path: '/:company_url/exports/sells',
    name: 'ExportSells',
    component: () => import('../pages/company/export/ExportSells'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterChecker(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'exports.menu', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/exports/purchases',
    name: 'ExportPurchases',
    component: () => import('../pages/company/export/ExportPurchase'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterChecker(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'exports.menu', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/export/products',
    name: 'ExportProducts',
    component: () => import('../pages/company/export/ExportProducts'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterChecker(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'exports.menu', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/export/contacts',
    name: 'ExportContacts',
    component: () => import('../pages/company/export/ExportContacts'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterChecker(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'exports.menu', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/export/absences',
    name: 'ExportAbsences',
    component: () => import('../pages/company/export/ExportAbsence'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterChecker(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'exports.menu', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/export/planning',
    name: 'ExportCalendarEvents',
    component: () => import('../pages/company/export/ExportCalendarEvent'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterChecker(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'exports.menu', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/export/deferred',
    name: 'ExportPackages',
    component: () => import('../pages/company/export/ExportPackages.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterChecker(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'exports.menu', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/export/fec/invoice',
    name: 'ExportFECInvoice',
    component: () => import('../pages/company/export/ExportFECInvoice.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterChecker(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'exports.menu', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/export/fec/purchase',
    name: 'ExportFECPurchase',
    component: () => import('../pages/company/export/ExportFECPurchase.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterChecker(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'exports.menu', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
]
