/* APP */
export const APP_STATUS = 'APP_STATUS'
export const APP_LOADING_PERCENT = 'APP_LOADING_PERCENT'
export const APP_TRIGGER_ROUTE = 'APP_TRIGGER_ROUTE'
export const MENU_ACTIVE = 'MENU_ACTIVE'
export const SET_VERSION = 'SET_VERSION'
export const SET_CONFIG = 'SET_CONFIG'

/* AUTH */
export const CURRENT_INVITATIONS = 'CURRENT_INVITATIONS'
export const CURRENT_INVITATION_UPDATE = 'CURRENT_INVITATION_UPDATE'
export const CURRENT_POSITION = 'CURRENT_POSITION'
export const CURRENT_NB_NOTIFICATIONS = 'CURRENT_NB_NOTIFICATIONS'
export const CURRENT_POSITIONS = 'CURRENT_POSITIONS'
export const CURRENT_USER = 'CURRENT_USER'
export const NAVIGATION_DATA = 'NAVIGATION_DATA'
export const NEW_NAVIGATION_DATA = 'NEW_NAVIGATION_DATA'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOG_OUT = 'LOG_OUT'
export const INVITATION_EMAIL = 'INVITATION_EMAIL'
export const TEXT_REGISTER = 'TEXT_REGISTER'
export const CURRENT_RIGHTS = 'CURRENT_RIGHTS'
export const CURRENT_MENUS = 'CURRENT_MENUS'
export const DEFAULT_TEAM = 'DEFAULT_TEAM'
export const CONFIG_APP = 'CONFIG_APP'
export const GLOBAL_MESSAGES = 'GLOBAL_MESSAGES'
export const CURRENT_SEARCH_OPTIONS_DOCUMENT_LINE = 'CURRENT_SEARCH_OPTIONS_DOCUMENT_LINE'

/* COMPANY CONTACTS */
export const COMPANY_CURRENT_CONTACTS = 'COMPANY_CURRENT_CONTACTS'
export const COMPANY_CURRENT_CONTACTS_ITEM = 'COMPANY_CURRENT_CONTACTS_ITEM'
export const COMPANY_CURRENT_CONTACTS_INFOS = 'COMPANY_CURRENT_CONTACTS_INFOS'
export const CURRENT_CONTACT = 'CURRENT_CONTACT'
export const CURRENT_CONTACT_STATUS = 'CURRENT_CONTACT_STATUS'

/* COMPANY INFOS */
export const COMPANY_TOKEN = 'COMPANY_TOKEN'
export const COMPANY_STATUS = 'COMPANY_STATUS'
export const COMPANY_LOADING_PERCENT = 'COMPANY_LOADING_PERCENT'
export const CURRENT_COMPANY = 'CURRENT_COMPANY'
export const CURRENT_SUBSCRIPTIONS = 'CURRENT_SUBSCRIPTIONS'
export const CURRENT_COMPANY_POSITIONS = 'CURRENT_COMPANY_POSITIONS'
export const CURRENT_COMPANY_POSITIONS_LOADING = 'CURRENT_COMPANY_POSITIONS_LOADING'
export const COMPANY_MISSING_FIELDS = 'COMPANY_MISSING_FIELDS'
export const COMPANY_NB_PURCHASES_BASKETS = 'COMPANY_NB_PURCHASES_BASKETS'

/* COMPANY INVOICE */
export const CURRENT_INVOICE = 'CURRENT_INVOICE'

/* COMPANY QUOTE */
export const CURRENT_QUOTE = 'CURRENT_QUOTE'

/* COMPANY RECEIPT */
export const CURRENT_RECEIPT = 'CURRENT_RECEIPT'

/* PURCHASES */
export const LOCAL_FILTERS_PURCHASES = 'LOCAL_FILTERS_PURCHASES'

/* SOCKETS */
export const SET_SOCKET = 'SET_SOCKET'
