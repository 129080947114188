import AppLayout from '../layouts/AppLayout'
import {resolveLoadingApp} from "@/utils/middlewares/auth.middleware"
import store from "@/store"

const beforeEnterCheckerSeeAbsences = function (to, from, next, redirect = { name: 'Error403' }) {
  let position = store.getters.currentPosition
  if (position) {
    let seeMy = store.getters.currentRights.SEE_MY_ABSENCES
    let seeTeam = store.getters.currentRights.SEE_TEAM_ABSENCES
    let seeAll = store.getters.currentRights.SEE_ALL_ABSENCES

    if (!seeMy && !seeTeam && !seeAll) return next(redirect)
  }

  next()
}

const beforeEnterCheckerAddAbsence = function (to, from, next, redirect = { name: 'Error403' }) {
  let position = store.getters.currentPosition
  if (position) {
    if (!store.getters.currentRights.CREATE_ABSENCE_TO_OTHER && !store.getters.currentRights.CREATE_ABSENCE && !store.getters.currentRights.MANAGE_ABSENCES) return next(redirect)

    if (!store.getters.currentRights.CREATE_ABSENCE_TO_OTHER && store.getters.currentRights.CREATE_ABSENCE && !position.isSalaried) return next(redirect)
  }

  next()
}

const beforeEnterCheckerAbsence = function (to, from, next, redirect = { name: 'Error403' }) {
  let position = store.getters.currentPosition
  if (position) {
    if (!store.getters.currentRights.MANAGE_ABSENCES) return next(redirect)
  }

  next()
}

const beforeEnterCheckerSeeCalendarEvent = function (to, from, next, redirect = { name: 'Error403' }) {
  let position = store.getters.currentPosition
  if (position) {
    let seeMy = store.getters.currentRights.SEE_MY_ABSENCES
    let seeTeam = store.getters.currentRights.SEE_TEAM_ABSENCES
    let seeAll = store.getters.currentRights.SEE_ALL_ABSENCES

    if (!seeMy && !seeTeam && !seeAll) return next(redirect)
  }

  next()
}

export default [
  {
    path: '/:company_url/calendar',
    name: 'Calendar',
    component: () => import('../pages/company/absences/Calendar.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerSeeAbsences(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'absences.title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/absences',
    name: 'Absences',
    component: () => import('../pages/company/absences/AbsenceCalendar.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerSeeAbsences(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'absences.title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/absences/list',
    name: 'AbsenceList',
    component: () => import('../pages/company/absences/AbsenceList.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerSeeAbsences(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'absences.title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/absences/add',
    name: 'AbsenceAdd',
    component: () => import('../pages/company/absences/AbsenceCreate.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAddAbsence(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'absences.add', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/absences/update/:id',
    name: 'AbsenceEdit',
    component: () => import('../pages/company/absences/Absence.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerAbsence(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'absences.edit', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
  {
    path: '/:company_url/planning',
    name: 'Planning',
    component: () => import('../pages/company/absences/PlanningCalendar.vue'),
    beforeEnter: async (to, from, next) => {
      await beforeEnterCheckerSeeCalendarEvent(to, from, next)

      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'absences.title', layout: AppLayout, activeMenu: "app", authenticate: true, company: true }
  },
]
