<template>
  <div class="limited" :class="arrays.type">
    <table class="table" v-if="arrays.type==='effectif'">
      <tr>
        <th>Dates</th>
        <th v-for="serie in arrays.seriesNames">{{ serie }}</th>
      </tr>
      <tr v-for="(c, index) in arrays.categories">
        <td>{{ c }}</td>
        <th v-for="data in arrays.series">{{ data[index] }}</th>
      </tr>
    </table>
    <table class="table" v-if="arrays.type==='graph'">
      <tr>
        <th>Dates</th>
        <th>{{arrays.seriesNames?arrays.seriesNames[0]:"Total"}}</th>
        <th v-if="arrays.series[1]">{{arrays.seriesNames?arrays.seriesNames[1]:"Nombre"}}</th>
        <th v-if="arrays.series[1]&&arrays.seriesNames">Différence</th>
        <th v-if="arrays.series[1]&&arrays.seriesNames">%</th>
      </tr>
      <tr v-for="(c, index) in arrays.categories">
        <td>{{ c }}</td>
        <td>
          <span v-if="arrays.seriesNames[0].includes('Ventes')">{{ (arrays.series[0][index])|price }}</span>
          <span v-else>{{ (arrays.series[0][index]) }}</span>
        </td>
        <td v-if="arrays.series[1]&&arrays.seriesNames">{{ (arrays.series[1][index])|price }}</td>
        <td v-if="arrays.series[1]&&!arrays.seriesNames">{{ arrays.series[1][index] }}</td>
        <td v-if="arrays.series[1]&&arrays.seriesNames">{{ (arrays.series[0][index] - arrays.series[1][index])|price }}</td>
        <td v-if="arrays.series[1]&&arrays.seriesNames" :class="evolution(arrays.series[0][index], arrays.series[1][index]) < 0 ? 'red' : 'green'">{{ evolution(arrays.series[0][index], arrays.series[1][index]).toFixed(2) }}%</td>
      </tr>
      <tr class="totals">
        <td>Total</td>
        <td>
          <span v-if="arrays.seriesNames[0].includes('Ventes')">{{ (arrays.series[0].reduce((a,c)=>a+c))|price }}</span>
          <span v-else>{{ (arrays.series[0].reduce((a,c)=>a+c)) }}</span>
        </td>
        <td v-if="arrays.series[1]&&arrays.seriesNames">{{ (arrays.series[1].reduce((a,c)=>a+c))|price }}</td>
        <td v-if="arrays.series[1]&&!arrays.seriesNames">{{ arrays.series[1].reduce((a,c)=>a+c) }}</td>
        <td v-if="arrays.series[1]&&arrays.seriesNames">{{ (arrays.series[0].reduce((a,c)=>a+c) - arrays.series[1].reduce((a,c)=>a+c))|price }}</td>
        <td v-if="arrays.series[1]&&arrays.seriesNames" :class="evolution(arrays.series[0].reduce((a,c)=>a+c), arrays.series[1].reduce((a,c)=>a+c)) < 0 ? 'red' : 'green'">{{ evolution(arrays.series[0].reduce((a,c)=>a+c), arrays.series[1].reduce((a,c)=>a+c)).toFixed(2) }}%</td>
      </tr>
    </table>
    <table class="table" v-if="arrays.type!='states'&&arrays.type!=='effectif'&&arrays.type!=='graph'">
      <tr>
        <th v-for="(value, name) in convertJson(arrays.seriesNames[0])">{{ $t('statistics.tab.' + name)}}</th>
        <th v-if="arrays.type==='products'">Pourcentage (nombre)</th>
        <th v-if="arrays.type==='products'">Pourcentage (montant)</th>
      </tr>
      <tr v-for="c in arrays.seriesNames">
        <td v-for="(value, name) in convertJson(c)">{{ format(value, name) }}</td>
        <td v-if="arrays.type==='products'">
          {{getPercentage(c, arrays.seriesNames, 'number')}}
        </td>
        <td v-if="arrays.type==='products'">
          {{getPercentage(c, arrays.seriesNames, 'total')}}
        </td>
      </tr>
    </table>
    <table class="table margin-b" v-if="arrays.type==='states'">
      <tr>
        <th v-for="(value, name) in convertJson(arrays.seriesNames[0])">{{ $t('statistics.tab.' + name)}}</th>
      </tr>
      <tr v-for="c in arrays.seriesNames">
        <td v-for="(value, name) in convertJson(c)">{{ format(value, name) }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { price, quantity } from '@/utils/helpers.service'

export default {
  name: 'TableStatistics',
  props: {
    arrays: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    getPercentage (c = {}, fullArr = [], prop = 'total') {
      let total = 0
      fullArr.forEach(line => {
        total += Number(this.convertJson(line)[prop])
      })
      return ((Number(this.convertJson(c)[prop]) / total) * 100).toFixed(2) + '%'
    },
    evolution (Va = 0, Vd = 0) {
      if (isNaN(Vd)) {
        return 0
      }
      if (Va > 0 && Vd === 0) {
        return 100
      }
      if (Vd === 0) {
        return 0
      }
      return ((Va - Vd) / Vd) * 100
    },
    IsJsonString (str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    },
    convertJson (value) {
      if (typeof value === "string" && this.IsJsonString(value)) {
        return JSON.parse(value)
      } else {
        return value
      }
    },
    format (value, name) {
      console.log(value, name)

      switch (name) {
        case "number": return quantity(value)
        case "total": return price(value)
        case "total_ht": return price(value)
        default: return value
      }
    }
  },
}
</script>

<style scoped>
.red {
  color: red;
}
.green {
  color: #1cb5a9;
}
.limited {
  display: inline-block;
  width: 100%;
  max-height: calc(100vh - 150px);
  overflow-y: auto;
}
.table th {
  background-color: #FEFEFE;
}
.table td {
  padding: 5px 20px;
}
.table tr.totals {
  font-weight: bold;
  background: #EEE;
}
</style>
