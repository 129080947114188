const objectFindDifference = function (obj1, obj2) {
  let keys = [...Object.keys(obj1), ...Object.keys(obj2)]

  for (let k = 0; k < keys.length; k++) {
    const key = keys[k]

    let field1 = obj1[key]
    let field2 = obj2[key]

    if (typeof field1 !== typeof field2) return true
    if (field1 && !field2) return true
    if (!field1 && field2) return true

    if (!field1 && !field2) continue

    if (typeof field1 === "object") {
      if (Array.isArray(field1)) {
        if (!Array.isArray(field2)) return true

        if (field1.length !== field2.length) return true

        for (let i = 0; i < field1.length; i++) {
          let hasDiff = objectFindDifference(field1[i], field2[i])
          if (hasDiff) return true
        }
      } else {
        let hasDifference = objectFindDifference(field1, field2)
        if (hasDifference) return true
      }
    } else {
      if (field1 !== field2) return true
    }
  }

  return false
}

export default {
  confirmLeavePage: async function (target, objectDefault) {
    let hasDifference = objectFindDifference(target, objectDefault)
    if (!hasDifference) return false

    this.$bvModal.msgBoxConfirm('', {
      title: 'Confirmation',
      size: 'md',
      okVariant: 'danger',
      okTitle: 'Rester sur la page',
      cancelVariant: 'light',
      cancelTitle: 'Partir sans enregistrer',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(value => {
      return value
    })
  }
}
