import axios from "axios";
import CryptoJS from "crypto-js";

const axiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/v3.2`,
  timeout: 100000,
  headers: {
    'Access-Control-Allow-Origin': 'Allow',
    'Content-Type': 'application/json',
    'X-ApiKey': process.env.VUE_APP_AUTOSIGNED_API_KEY,
    'X-Signature': null,
    'X-Datetime': null
  }
})

const checkHeaders = (axiosI, method, uri) => {
  let dateTime = new Date().getTime()
  let word = method + uri + dateTime

  axiosI.defaults.headers['X-ApiKey'] = process.env.VUE_APP_TEST_AUTOSIGNED_API_KEY
  axiosI.defaults.headers['X-Signature'] = CryptoJS.HmacSHA1(word, process.env.VUE_APP_TEST_AUTOSIGNED_API_SECRET)
  axiosI.defaults.headers['X-Datetime'] = dateTime
}

export default {
  postInvoice (data = {}) {
    const uri = '/invoice'
    checkHeaders(axiosInstance, "POST", uri)

    return axiosInstance.post(uri, data.body, { params: data.filters })
  },
  patchInvoice (data = {}) {
    const uri = '/invoice/' + data.id
    checkHeaders(axiosInstance, "PATCH", uri)

    return axiosInstance.patch(uri, data.body, { params: data.filters })
  },
}
