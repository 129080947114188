import axios from 'axios'
import CryptoJS from 'crypto-js'

import Absence from './absence.apifactory'
import Activity from './activity.apifactory'
import Application from './applications.apifactory'
import BankReconciliation from './bankReconciliation.apifactory'
import BankReconciliationCount from './bankReconciliationCount.apifactory'
import Basket from './basket.apifactory'
import CalendarEvent from './calendarEvent.apifactory'
import Client from './client.apifactory'
import DeliveryReceipt from './deliveryReceipt.apifactory'
import DocumentModel from './documentModel.apifactory'
import DocumentNumber from './documentNumber.apifactory'
import DunningRule from './dunningRule.apifactory'
import Email from './email.apifactory'
import Export from './export.apifactory'
import Interlocutor from './interlocutors.apifactory'
import Invitation from './invitation.apifactory'
import Invoice from './invoice.apifactory'
import InvoiceTvaCount from './invoiceTvaCount.apifactory'
import InvoiceRecurrence from './invoiceRecurrence.apifactory'
import MailModel from './mailModel.apifactory'
import Module from './module.apifactory'
import Note from './note.apifactory'
import NotificationsList from './notifications-list.apifactory'
import Operation from './operation.apifactory'
import Order from './order.apifactory'
import Package from './package.apifactory'
import PaymentMethod from './paymentMethod.apifactory'
import Payroll from './payroll.apifactory'
import Position from './position.apifactory'
import Product from './product.apifactory'
import ProductCategory from './productCategory.apifactory'
import ProductCount from './productCount.apifactory'
import ProductVariant from './productVariant.apifactory'
import Purchase from './purchase.apifactory'
import PurchaseCategory from './purchaseCategory.apifactory'
import PurchaseTvaCount from './purchaseTvaCount.apifactory'
import PurchaseExpenseCount from "./purchaseExpenseCount.apifactory"
import Quote from './quote.apifactory'
import RightType from './rightType.apifactory'
import SafeFile from './safeFile.apifactory'
import Service from './service.apifactory'
import Statistics from './stats.apifactory'
import Subscription from './subscription.apifactory'
import Tag from './tags.apifactory'
import Team from './team.apifactory'
import Webhook from './webhook.apifactory'

const axiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/v3.2`,
  timeout: 90000,
  headers: {
    'Access-Control-Allow-Origin': 'Allow',
    'Content-Type': 'application/json',
    'X-ApiKey': process.env.VUE_APP_AUTOSIGNED_API_KEY,
    'X-Signature': null,
    'X-Datetime': null
  }
})

const checkHeaders = (axiosI, method, uri) => {
  if (!axiosI.defaults.headers.Authorization) {
    let dateTime = new Date().getTime()
    let word = method + uri + dateTime

    axiosI.defaults.headers['X-ApiKey'] = process.env.VUE_APP_AUTOSIGNED_API_KEY
    axiosI.defaults.headers['X-Signature'] = CryptoJS.HmacSHA1(word, process.env.VUE_APP_AUTOSIGNED_API_SECRET)
    axiosI.defaults.headers['X-Datetime'] = dateTime
  } else {
    delete axiosI.defaults.headers['X-ApiKey']
    delete axiosI.defaults.headers['X-Signature']
    delete axiosI.defaults.headers['X-Datetime']
  }
}

export default {
  // HELPERS
  setAuthorization (token) {
    axiosInstance.defaults.headers.Authorization = 'Bearer ' + token
  },
  removeAuthorization () {
    delete axiosInstance.defaults.headers['Authorization']
  },
  // MODULES
  getOne (data = {}) {
    const uri = '/company/' + data.id
    checkHeaders(axiosInstance, "GET", uri)

    return axiosInstance.get(uri, { params: data.filters })
  },
  getAppInfos (data = {}) {
    const uri = '/company/' + data.id + '/app-infos'
    checkHeaders(axiosInstance, "GET", uri)

    return axiosInstance.get(uri, { params: data.filters })
  },
  getCgv (data = {}) {
    const uri = '/company/' + data.id + '/cgv'
    checkHeaders(axiosInstance, "GET", uri)

    return axiosInstance.get(uri, { params: data.filters })
  },
  getCurrent (data = {}) {
    const uri = '/company'
    checkHeaders(axiosInstance, "GET", uri)

    return axiosInstance.get(uri, { params: data.filters })
  },
  patchCurrent (data = {}) {
    const uri = '/company/' + data.id
    checkHeaders(axiosInstance, "PATCH", uri)

    return axiosInstance.patch(uri, data.body, { params: data.filters })
  },
  getCurrentAppInfos (data = {}) {
    const uri = '/company/app-infos'
    checkHeaders(axiosInstance, "GET", uri)

    return axiosInstance.get(uri, { params: data.filters })
  },
  getContainerStats (data = {}) {
    const uri = '/company/container-stats'
    checkHeaders(axiosInstance, "GET", uri)

    return axiosInstance.get(uri, { params: data.filters })
  },
  getPayment (data = {}) {
    const uri = '/order/' + data.id + '/payment-infos'
    checkHeaders(axiosInstance, "GET", uri)

    return axiosInstance.get(uri, { params: data.filters })
  },
  getSecureFile (data = {}) {
    const uri = '/secure-file'
    checkHeaders(axiosInstance, "POST", uri)

    return axiosInstance.post(uri, data.body, { params: data.filters || {} })
  },
  getSecureFiles (data = {}) {
    const uri = '/secure-files'
    checkHeaders(axiosInstance, "POST", uri)

    return axiosInstance.post(uri, data.body, { params: data.filters || {} })
  },
  absence: Absence(axiosInstance),
  activity: Activity(axiosInstance),
  application: Application(axiosInstance),
  bankReconciliation: BankReconciliation(axiosInstance),
  bankReconciliationCount: BankReconciliationCount(axiosInstance),
  basket: Basket(axiosInstance),
  calendarEvent: CalendarEvent(axiosInstance),
  client: Client(axiosInstance),
  deliveryReceipt: DeliveryReceipt(axiosInstance, checkHeaders),
  documentModel: DocumentModel(axiosInstance),
  documentNumber: DocumentNumber(axiosInstance),
  dunningRule: DunningRule(axiosInstance),
  email: Email(axiosInstance),
  export: Export(axiosInstance),
  interlocutor: Interlocutor(axiosInstance),
  invitation: Invitation(axiosInstance),
  invoice: Invoice(axiosInstance, checkHeaders),
  invoiceTvaCount: InvoiceTvaCount(axiosInstance),
  invoiceRecurrence: InvoiceRecurrence(axiosInstance),
  mailModel: MailModel(axiosInstance),
  module: Module(axiosInstance),
  note: Note(axiosInstance),
  notificationsList: NotificationsList(axiosInstance),
  operation: Operation(axiosInstance),
  order: Order(axiosInstance),
  package: Package(axiosInstance),
  paymentMethod: PaymentMethod(axiosInstance),
  payroll: Payroll(axiosInstance),
  position: Position(axiosInstance, checkHeaders),
  product: Product(axiosInstance),
  productCategory: ProductCategory(axiosInstance),
  productCount: ProductCount(axiosInstance),
  productVariant: ProductVariant(axiosInstance),
  purchase: Purchase(axiosInstance),
  purchaseCategory: PurchaseCategory(axiosInstance),
  purchaseTvaCount: PurchaseTvaCount(axiosInstance),
  purchaseExpenseCount: PurchaseExpenseCount(axiosInstance),
  quote: Quote(axiosInstance, checkHeaders),
  rightType: RightType(axiosInstance),
  safeFile: SafeFile(axiosInstance),
  service: Service(axiosInstance),
  stats: Statistics(axiosInstance),
  subscription: Subscription(axiosInstance),
  tag: Tag(axiosInstance),
  team: Team(axiosInstance),
  webhook: Webhook(axiosInstance)
}
