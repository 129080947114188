<template>
  <div class="empty-state">
    <div class="super-icon" :class='icon'></div>
    {{ text }}
    <router-link :to="action" v-if="action && actionText" @click="action">{{ actionText }}</router-link>
  </div>
</template>

<script>
export default {
  name: 'EmptyState',
  props: {
    icon: {
      type: String,
      default: ''
    },
    action: {
      type: Object,
    },
    actionText: {
      type: String,
    },
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

