import api from '../../utils/api_factory'
import to from 'await-to-js'

const state = {}

const getters = {}

const actions = {
  // TODO pas obligé de passer par le store, on peut appeler directement api.user.createCompany en important l'apiFactory
  $a_createCompany: async function ({ commit, dispatch }, data = {}) {
    let [err, resp] = await to(api.user.createCompany(data))
    if (err) return { err: err.response.data }

    return resp.data
  },
  $a_createPosition: async function ({ commit, dispatch }, data = {}) {
    let [err, resp] = await to(api.user.createPosition(data))

    if (err) return { err: err.response.data }

    return resp.data
  }
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
