export default (axios, checkHeaders) => ({
  getList (data = {}) {
    const uri = '/positions'
    checkHeaders(axios, "GET", uri)

    return axios.get(uri, { params: data.filters })
  },
  postOne (data = {}) {
    return axios.post('/position', data.body, { params: data.filters || {} })
  },
  getOne (data = {}) {
    return axios.get('/position/' + data.id, { params: data.filters || {} })
  },
  patchOne (data = {}) {
    return axios.patch('/position/' + data.id, data.body, { params: data.filters || {} })
  },
  deleteOne (data = {}) {
    return axios.delete('/position/' + data.id, { params: data.filters || {} })
  }
})
