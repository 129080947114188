export default (axios, checkHeaders) => ({
  getList (data = {}) {
    return axios.get('/bank-reconciliations', { params: data.filters || {} })
  },
  postOne (data = {}) {
    return axios.post('/bank-reconciliation', data.body, { params: data.filters || {} })
  },
  getOne (data = {}) {
    return axios.get('/bank-reconciliation/' + data.id, { params: data.filters || {} })
  },
  patchOne (data = {}) {
    return axios.patch('/bank-reconciliation/' + data.id, data.body, { params: data.filters || {} })
  },
  deleteOne (data = {}) {
    return axios.delete('/bank-reconciliation/' + data.id, { params: data.filters || {} })
  },
  reconciliate (data = {}) {
    return axios.post('/bank-reconciliation/' + data.id + '/reconciliate', data.body, { params: data.filters || {} })
  }
})
