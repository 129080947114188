import {mapActions} from "vuex"

export default {
  data () {
    return {
      categoriesToMoove: []
    }
  },
  methods: {
    ...mapActions(['$a_loadCompanyInfos', '$a_refreshPosition']),
    foundCompanyStatFiles (fileTypes) {
      if (!fileTypes) return null

      let count = 0
      if (!this.currentCompany.statsSafeFiles) return 0

      fileTypes.forEach(type => {
        count += this.currentCompany.statsSafeFiles[type] || 0
      })

      return count
    },
    foundPositionStatFiles (fileTypes, position, field = 'statsSafeFiles') {
      if (!fileTypes) return null

      let count = 0
      if (!position[field]) return 0

      fileTypes.forEach(type => {
        count += position[field][type] || 0
      })

      return count
    },
    findCategory (categoryName, categories) {
      if (!categories || (categories && categories.length === 0)) return null

      let foundCategory = null

      for (let category of categories) {
        if (category.name === categoryName) return category
        if (category.children) foundCategory = this.findCategory(categoryName, category.children)
      }

      return foundCategory
    },
    getCategoryTree (searchCategory, categories) {
      let breadcrumb = []

      let i = 0
      while (breadcrumb.length === 0 && i < categories.length) {
        let category = categories[i]

        if (searchCategory.name === category.name) {
          breadcrumb.push({ label: this.$t("safe.category." + category.name + ".label"), route: { name: category.routeName, params: { category: category.name, company_url: this.currentCompany.url } } })
        } else {
          if (category.children && category.children.length > 0) {
            let subBreadcrumb = this.getCategoryTree(searchCategory, category.children)
            if (subBreadcrumb.length > 0) {
              breadcrumb.push({ label: this.$t("safe.category." + category.name + ".label"), route: { name: category.routeName, params: { category: category.name, company_url: this.currentCompany.url } } })
              breadcrumb = breadcrumb.concat(subBreadcrumb)
            }
          }
        }

        i++
      }

      return breadcrumb
    },
    refreshData () {
      this.$a_loadCompanyInfos()

      if (this.file.forEntityModel === 'Position' && this.file.forEntity) {
        this.$a_refreshPosition({ positionId: this.file.forEntity._id, timeout: 500 })
      }
    },
    setCategoriesToMoove (categories, filterPositionCategories = true, filterCurrentCategory = true) {
      let categoryList = this.getCategoriesToMoove(categories, filterPositionCategories)

      if (filterCurrentCategory) {
        categoryList = categoryList.filter(c => c.name !== this.$route.params.category)
      }

      categoryList = categoryList.map(c => {
        return {
          name: c.name,
          label: this.$t("safe.category." + c.name + ".label")
        }
      })

      categoryList.unshift({ name: null, label: '-- Choisir une catégorie --' })
      this.categoriesToMoove = categoryList
    },
    getCategoriesToMoove (categories, filterPositionCategories = true) {
      let categoriesForm = []

      categories.forEach(c => {
        if (c.name === "payrolls") return
        if (filterPositionCategories && c.routeName === 'SafeCategoryPositions') return

        if (c.children) {
          categoriesForm = categoriesForm.concat(categoriesForm, this.getCategoriesToMoove(c.children, filterPositionCategories))
        } else {
          categoriesForm.push(c)
        }
      })

      return categoriesForm
    },
  }
}
