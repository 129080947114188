import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import authV3 from './modules/authV3.2'
import company from './modules/company'
import contact from './modules/contact'
import deliveryReceipt from './modules/deliveryReceipt'
import invoice from './modules/invoice'
import purchase from './modules/purchase'
import quote from './modules/quote'
import service from './modules/service'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    app,
    authV3,
    company,
    contact,
    deliveryReceipt,
    invoice,
    purchase,
    quote,
    service,
  },
  strict: debug,
})
