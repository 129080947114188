export default [
  {
    // Fiches de paie
    name: 'payrolls',
    routeName: 'SafeRHCategoryPositions',
    onPositionClickRoute: (position, self) => {
      return { name: 'PositionPayrolls', params: { company_url: self.currentCompany.url, position: position._id } }
    },
    onAddClickRoute: (position, self) => {
      return { name: 'PayrollsCreate', params: { company_url: self.currentCompany.url, position: position._id } }
    },
    img: 'payrolls.svg',
  },
  {
    // Avantages
    name: 'advantage',
    routeName: 'SafeRHCategoryFiles',
    img: 'advantage.svg',
    file_type: ['ticket_restaurant', 'gift_check', 'advantages_in_kind', 'other_advantage']
  },
  {
    // Entretiens
    name: 'interviews',
    img: 'interviews.svg',
    routeName: 'SafeRHCategoryPositions',
    file_type: ['ei', 'ea', 'ep', 'other_interview']
  },
  {
    // Contrats de travail
    name: 'contracts',
    img: 'contracts.svg',
    routeName: 'SafeRHCategoryPositions',
    file_type: ['job_description', 'endorsement', 'internship_agreement', 'work_certificate', 'employment_center_certificate', "work_contract", "received_for_final_settlement", "work_study_agreement", "job_promise", "rib_position", "advantage", "training", "candidacy", "other_contract"]
  },
  {
    // Absences
    name: 'absences',
    img: 'absences.svg',
    routeName: 'SafeRHCategoryPositions',
    file_type: ['summons', 'discharge', 'supporting_documents', 'maternity_leave', 'paternity_leave', 'other_absence']
  },
  {
    // Santé
    name: 'health',
    img: 'health.svg',
    routeName: 'SafeRHCategoryPositions',
    file_type: ['occupational_medicine', 'mutual', "other_heath"]
  },
  {
    // Formations
    name: 'trainings',
    routeName: 'SafeRHCategoryFiles',
    img: 'training.svg',
    file_type: ['training_document', 'other_training']
  },
  {
    // Ressources de l'entreprise
    name: 'ressource',
    img: 'ressources.svg',
    routeName: 'SafeRHCategoryFiles',
    file_type: ['company_ressource', 'collective_agreement', 'internal_regulation', 'other_ressource']
  }
]
