import {
  COMPANY_CURRENT_CONTACTS,
  COMPANY_CURRENT_CONTACTS_ITEM,
  COMPANY_CURRENT_CONTACTS_INFOS,
  CURRENT_CONTACT,
  CURRENT_CONTACT_STATUS,
} from "../mutation.constant"
import api from '../../utils/api_factory'
import to from 'await-to-js'
import {dataSnifer} from "@/utils/helpers.service"

const DEFAULT_CONTACT_INFOS = {
  currentFilters: {
    search: '',
    tag: [],
    email: null,
    phone: null,
    mobile: null,
    client_code: null,
    analytical_code: null,
    positionsAssigned: []
  },
  total: 0,
  page: 1
}

const state = {
  currentContact: {},
  currentContactStatus: null,
  currentContacts: [],
  currentContactsInfos: JSON.parse(JSON.stringify(DEFAULT_CONTACT_INFOS))
}

const getters = {
  currentContact: state => state.currentContact,
  currentContactStatus: state => state.currentContactStatus,
  currentContacts: state => state.currentContacts,
  currentContactsInfos: state => state.currentContactsInfos,
}

const actions = {
  $a_setCurrentContact: async function ({ state, commit }, { id, force }) {
    if (id === null) {
      commit(CURRENT_CONTACT, { name: null })
      return
    }

    if ((state.currentContact._id !== id) || force) {
      // Dans le cas d'un refresh des données on ne set pas l'état du chargement (évite que la page ne saute)
      if (state.currentContact._id !== id) commit(CURRENT_CONTACT_STATUS, 'loading')

      let [err, response] = await to(api.company.client.getOne({ id, filters: { populate: 'tags interlocutors positionsAssigned' } }))
      if (err) {
        if (err.response.status === 403) { commit(CURRENT_CONTACT_STATUS, 'Error403'); return }
        if (err.response.status === 500) { commit(CURRENT_CONTACT_STATUS, 'Error500'); return }
        commit(CURRENT_CONTACT_STATUS, 'Error404'); return
      }

      let contact = response.data.client

      // On retire les interlocuteurs inactifs (en attendant que l'API arrive à le faire)
      if (contact.interlocutors) {
        contact.interlocutors = contact.interlocutors.filter(i => i.state !== 'inactive')
      }

      if (!contact.stats) contact.stats = {}
      if (!contact.stats.nbQuotes) contact.stats.nbQuotes = 0
      if (!contact.stats.nbInvoices) contact.stats.nbInvoices = 0
      if (!contact.stats.nbNotes) contact.stats.nbNotes = 0
      if (!contact.stats.nbReminders) contact.stats.nbReminders = 0
      if (!contact.stats.nbFiles) contact.stats.nbFiles = 0
      if (!contact.stats.nbPurchases) contact.stats.nbPurchases = 0
      if (!contact.stats.nbInterlocutors) contact.stats.nbInterlocutors = 0

      if (contact.interlocutors) contact.stats.nbInterlocutors = contact.interlocutors.length
      if (!contact.tags) contact.tags = []

      commit(CURRENT_CONTACT_STATUS, null)
      commit(CURRENT_CONTACT, contact)
    }
  },
  $a_setCurrentContactsItem: function ({ state, commit }, contact) {
    commit(COMPANY_CURRENT_CONTACTS_ITEM, contact)
  },
  $a_resetContactStore: async function ({ state, commit }) {
    commit(CURRENT_CONTACT, {})
    commit(COMPANY_CURRENT_CONTACTS, [])
    commit(COMPANY_CURRENT_CONTACTS_INFOS, DEFAULT_CONTACT_INFOS)
  },
  $a_updateStatsCurrentContact: function ({ state, commit }, data) {
    let currentContact = JSON.parse(JSON.stringify(state.currentContact))

    currentContact.stats = {...currentContact.stats, ...data}

    commit(CURRENT_CONTACT, currentContact)
  },
  $a_setCurrentContacts: async function ({ state, commit }, data = {}) {
    let filters = data.filters || state.currentContactsInfos.currentFilters || {}
    let force = data.force || false
    let page = data.page || state.currentContactsInfos.page || 1
    let limit = data.limit || 50

    if (JSON.stringify(state.currentContactsInfos.currentFilters) === JSON.stringify(filters) && page === state.currentContactsInfos.page && !force) {
      const data = {
        clients: state.currentContacts,
        total: state.currentContactsInfos.currentFilters
      }

      return { data }
    }

    let [err, response] = await to(api.company.client.getList({ filters: { ...filters, page, limit, populate: 'tags interlocutors positionsAssigned' } }))
    if (err) {
      commit(COMPANY_CURRENT_CONTACTS, [])
      return { err: err.response.data }
    }

    let contacts = response.data.clients.map(c => {
      let contact = dataSnifer(c, '_id avatar address address_end zip_code city country email phone mobile name stats positionsAssigned updatedAt interlocutors tags')
      if (contact.interlocutors) {
        contact.interlocutors = contact.interlocutors.filter(i => i.state !== 'inactive')
        contact.interlocutors = contact.interlocutors.map(i => {
          return dataSnifer(i, '_id avatar first_name last_name')
        })
      }

      if (contact.positionsAssigned) {
        contact.positionsAssigned = contact.positionsAssigned.map(pA => dataSnifer(pA, '_id avatar first_name last_name'))
      }

      contact.checked = false
      return contact
    })

    commit(COMPANY_CURRENT_CONTACTS, contacts)
    commit(COMPANY_CURRENT_CONTACTS_INFOS, { currentFilters: filters, total: response.data.total, page: page })

    return { data: response.data }
  },
  $a_checkContact: async function ({ state, commit }, data) {
    let contacts = JSON.parse(JSON.stringify(state.currentContacts))

    contacts = contacts.map(c => {
      if (c._id === data.contactId) {
        c.checked = data.value
      }
      return c
    })

    commit(COMPANY_CURRENT_CONTACTS, contacts)
  }
}

const mutations = {
  [COMPANY_CURRENT_CONTACTS]: (state, contacts) => {
    state.currentContacts = contacts
  },
  [COMPANY_CURRENT_CONTACTS_ITEM]: (state, contact) => {
    if (state.currentContacts) {
      let index = state.currentContacts.findIndex(c => c._id === contact._id)
      if (index >= 0) {
        state.currentContacts[index] = contact
      }
    }
  },
  [COMPANY_CURRENT_CONTACTS_INFOS]: (state, infos) => {
    state.currentContactsInfos = {...state.currentContactsInfos, ...infos}
  },
  [CURRENT_CONTACT_STATUS]: (state, status) => {
    state.currentContactStatus = status
  },
  [CURRENT_CONTACT]: (state, contact) => {
    state.currentContact = contact

    if (state.currentContacts) {
      let foundIndex = state.currentContacts.findIndex(c => c._id === contact._id)
      if (foundIndex >= 0) {
        let c = dataSnifer(contact, '_id avatar address address_end zip_code city country email phone mobile name stats positionsAssigned updatedAt interlocutors tags')
        if (c.interlocutors) {
          c.interlocutors = c.interlocutors.filter(i => i.state !== 'inactive')
          c.interlocutors = c.interlocutors.map(i => {
            return dataSnifer(i, '_id avatar first_name last_name')
          })
        }

        if (c.positionsAssigned) {
          c.positionsAssigned = c.positionsAssigned.map(pA => dataSnifer(pA, '_id avatar first_name last_name'))
        }

        c.checked = false

        state.currentContacts[foundIndex] = c
      }
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
