export default (axios, checkHeaders) => ({
  getList (data = {}) {
    return axios.get('/operations', { params: data.filters || {} })
  },
  postClosure (data = {}) {
    return axios.post('/operation/closure', data.body, { params: data.filters })
  },
  patchOne (data = {}) {
    return axios.patch('/operation/' + data.id, data.body, { params: data.filters || {} })
  },
  getOne (data = {}) {
    return axios.get('/operation/' + data.id, { params: data.filters || {} })
  }
})
