export default (axios, checkHeaders) => ({
  getList (data = {}) {
    return axios.get('/product-variants', { params: data.filters || {} })
  },
  getProductList (data = {}) {
    return axios.get('/product/' + data.id + '/variants', { params: data.filters || {} })
  },
  postOne (data = {}) {
    return axios.post('/product/' + data.id + '/variant', data.body, { params: data.filters || {} })
  },
  getOne (data = {}) {
    return axios.get('/product/' + data.productId + '/variant/' + data.id, { params: data.filters || {} })
  },
  patchOne (data = {}) {
    return axios.patch('/product/' + data.productId + '/variant/' + data.id, data.body, { params: data.filters || {} })
  },
  deleteOne (data = {}) {
    return axios.delete('/product/' + data.productId + '/variant/' + data.id, { params: data.filters || {} })
  }
})
