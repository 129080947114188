import AppLayout from '../layouts/AppLayout'
import {resolveLoadingApp} from "@/utils/middlewares/auth.middleware"
import store from "../store"

export default [
  {
    path: '/:company_url/my-notifications-list',
    name: 'MyNotifications',
    component: () => import('../pages/company/notifications/MyNotifications.vue'),
    beforeEnter: async (to, from, next) => {
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'home.title', layout: AppLayout, activeMenu: 'notification', authenticate: true, company: true }
  },
  {
    path: '/:company_url/notifications-list',
    name: 'Notifications',
    component: () => import('../pages/company/notifications/Notifications.vue'),
    beforeEnter: async (to, from, next) => {
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'home.title', layout: AppLayout, activeMenu: 'notification', authenticate: true, company: true }
  },
  {
    path: '/:company_url/notifications-list/:id/position/:position',
    name: 'NotificationsList',
    component: () => import('../pages/company/notifications/Notification.vue'),
    beforeEnter: async (to, from, next) => {
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'home.title', layout: AppLayout, activeMenu: 'notification', authenticate: true, company: true }
  },
  {
    path: '/:company_url/notifications-list/create',
    name: 'NotificationsListCreate',
    component: () => import('../pages/company/notifications/NotificationCreate.vue'),
    beforeEnter: async (to, from, next) => {
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'home.title', layout: AppLayout, activeMenu: 'notification', authenticate: true, company: true }
  },
  {
    path: '/:company_url/notifications-list/update/:id',
    name: 'NotificationsListUpdate',
    component: () => import('../pages/company/notifications/NotificationUpdate.vue'),
    beforeEnter: async (to, from, next) => {
      await resolveLoadingApp(to, from, next)
    },
    meta: { title: 'home.title', layout: AppLayout, activeMenu: 'notification', authenticate: true, company: true }
  }
]
