export default (axios, checkHeaders) => ({
  getList (data = {}) {
    return axios.get('/invoices', { params: data.filters || {} })
  },
  postOne (data = {}) {
    return axios.post('/invoice', data.body, { params: data.filters || {} })
  },
  getOne (data = {}) {
    return axios.get('/invoice/' + data.id, { params: data.filters || {} })
  },
  getStats (data = {}) {
    return axios.get('/invoices/stats', { params: data.filters || {} })
  },
  getTurnover (data = {}) {
    return axios.get('/invoices/turnover', { params: data.filters || {} })
  },
  createPackage (data = {}) {
    return axios.post('/invoices/package', data.body, { params: data.filters || {} })
  },
  getHtml (data = {}) {
    checkHeaders(axios, 'GET', '/invoice/' + data.id + '/html')

    return axios.get('/invoice/' + data.id + '/html', data.body, { params: data.filters || {} })
  },
  createPayment (data = {}) {
    checkHeaders(axios, 'POST', '/invoice/' + data.id + '/payment')

    return axios.post('/invoice/' + data.id + '/payment', data.body, { params: data.filters || {} })
  },
  patchOne (data = {}) {
    return axios.patch('/invoice/' + data.id, data.body, { params: data.filters || {} })
  },
  deleteOne (data = {}) {
    return axios.delete('/invoice/' + data.id, { params: data.filters || {} })
  },
  postCredit (data = {}) {
    return axios.post('/invoice/' + data.id + '/credit', data.body, { params: data.filters || {} })
  },
  getLogs (data = {}) {
    if (data.id) return axios.get('/invoice/' + data.id + '/logs', { params: data.filters || {} })

    return axios.get('/invoices/logs', { params: data.filters || {} })
  },
  postFile (data = {}) {
    return axios.post('/invoice-file', data.body, { params: data.filters || {} })
  },
  postDeliveryReceipt (data = {}) {
    return axios.post('/invoice/' + data.id + '/delivery-receipt', data.body, { params: data.filters || {} })
  },
})
