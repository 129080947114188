export default (axios, checkHeaders) => ({
  getList (data = {}) {
    return axios.get('/bank-reconciliation-counts', { params: data.filters || {} })
  },
  postOne (data = {}) {
    return axios.post('/bank-reconciliation-count', data.body, { params: data.filters || {} })
  },
  getOne (data = {}) {
    return axios.get('/bank-reconciliation-count/' + data.id, { params: data.filters || {} })
  },
  patchOne (data = {}) {
    return axios.patch('/bank-reconciliation-count/' + data.id, data.body, { params: data.filters || {} })
  },
  deleteOne (data = {}) {
    return axios.delete('/bank-reconciliation-count/' + data.id, { params: data.filters || {} })
  }
})
