import {
  APP_LOADING_PERCENT,
  APP_STATUS,
  APP_TRIGGER_ROUTE,
  MENU_ACTIVE, SET_CONFIG,
  SET_VERSION
} from "../mutation.constant"

const state = {
  appConfig: {},
  appLoadingPercent: 0,
  appStatus: 'loading', // Global loading of the App
  appTriggerRoute: null,
  appVersion: null,
  menuActive: null,
}

const getters = {
  appTriggerRoute: state => state.appTriggerRoute,
  appConfig: state => state.appConfig,
  appVersion: state => state.appVersion,
  isLoadingApp: state => state.appStatus === 'loading',
  loadingPercent: state => state.appLoadingPercent,
  menuActive: state => state.menuActive,
}

const actions = {
  $a_loadUserAppInfos ({ commit, dispatch }) {
    commit(APP_STATUS, 'loading')
    commit(APP_LOADING_PERCENT, 0)
  },
  $a_stopLoadingAppInfos ({ commit }) {
    commit(APP_STATUS, 'started')
    commit(APP_LOADING_PERCENT, 0)
  },
  $a_setTriggerRoute ({commit}, to) {
    commit(APP_TRIGGER_ROUTE, to)
  },
  $a_setMenuActive ({ commit, state }, menu) {
    if (state.menuActive !== menu) {
      commit(MENU_ACTIVE, menu)
    }
  },
  $a_setVersion ({ commit, state }, version) {
    commit(SET_VERSION, version)
  },
  $a_setConfigApp ({ commit, state }, config) {
    commit(SET_CONFIG, config)
  }
}

const mutations = {
  [APP_STATUS]: (state, appStatus) => {
    state.appStatus = appStatus
  },
  [APP_LOADING_PERCENT]: (state, appLoadingPercent) => {
    state.appLoadingPercent = appLoadingPercent
  },
  [APP_TRIGGER_ROUTE]: (state, to) => {
    state.appTriggerRoute = to
  },
  [MENU_ACTIVE]: (state, menu) => {
    state.menuActive = menu
  },
  [SET_CONFIG]: (state, config) => {
    state.appConfig = config
  },
  [SET_VERSION]: (state, data) => {
    state.appVersion = data
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
