// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import PACKAGE_JSON from '../package.json'

// COMPONENTS
import Vue from 'vue'
import App from './App'
import AlertIcon from './components/AlertIcon'
import EmptyState from './components/EmptyState'
import Summary from './components/Summary'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import ToggleButton from 'vue-js-toggle-button'
import DatePicker from 'vue2-datepicker'
import VueSuggest from 'vue-suggest' // TODO à supprimer un jour
import LightTimeline from 'vue-light-timeline' // TODO trouver un meilleur composant et pouvoir l'importer juste dans le composant utilisé et non dans le global
import GSignInButton from 'vue-google-signin-button'
import People from './components/People.vue'
import VeeValidate from 'vee-validate'
import validationMessages from 'vee-validate/dist/locale/fr'
import AlertDunningVersion from "@/components/Alert/AlertDunningVersion"
import PositionAvatar from "@/components/Position/PositionAvatar"
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import FECIcon from "@/components/FECIcon"

// LIBS, HELPERS, MIXINS
import api from '/src/utils/api_factory'
import router from './router'
import store from './store'
import toast from './utils/toast.service'
import confirmBox from './utils/mixins/confirm.mixin'
import confirmLeavePage from './utils/mixins/confirm_leave_page.mixin'
import VueI18n from 'vue-i18n'
import {redirectError} from './utils/helpers.service'
import { library } from '@fortawesome/fontawesome-svg-core'
//import VueSocketIO from 'vue-socket.io'
//import SocketIO from 'socket.io-client'
import moment from 'moment'
import {RIGHTS} from "@/utils/rights.service"

// CSS
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.min.css'
import './assets/css/bootstrap-theming.scss'
import './assets/css/global.css'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
// Filters
import filterPrice from './utils/filters/formatPrice.filter'
import filtersDate from './utils/filters/dates.filter'
import filtersName from './utils/filters/names.filter'
import {mapGetters} from "vuex"

// Font Awesome
import { faStethoscope, faPersonWalkingLuggage, faUserClock, faSackXmark, faCircleExclamation, faStamp, faCircleHalfStroke, faCircle, faMugHot, faPizzaSlice, faCalendarWeek, faHouseLaptop, faClock, faUserGraduate, faCalendarDays, faCalendarDay, faListUl, faEllipsis, faSquareCheck, faSquareXmark, faEyeSlash, faFileExport, faClone } from '@fortawesome/free-solid-svg-icons'
library.add(faStethoscope, faPersonWalkingLuggage, faUserClock, faSackXmark, faCircleExclamation, faStamp, faCircleHalfStroke, faCircle, faMugHot, faPizzaSlice, faCalendarWeek, faHouseLaptop, faClock, faUserGraduate, faCalendarDays, faCalendarDay, faListUl, faEllipsis, faSquareCheck, faSquareXmark, faEyeSlash, faFileExport, faClone)

const locale = 'fr'// Force FR navigator.language.split('-')[0] || navigator.userLanguage.split('-')[0] || 'fr'
moment.locale(locale)

store.dispatch('$a_setVersion', PACKAGE_JSON.version)

/*Vue.use(new VueSocketIO({
  debug: true,
  connection: process.env.VUE_APP_SOCKET_URL,
  vuex: {
    store,
  },
}))*/

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(DatePicker)
// Vue.use(VueSocketIO, SocketInstance)
Vue.use(GSignInButton) // Google connection
Vue.use(VueI18n)       // Internationalization
Vue.use(ToggleButton) // Switch
Vue.use(LightTimeline)

// Global Components
Vue.component('AlertIcon', AlertIcon)
Vue.component('AlertRhAvailable', () => import('@/components/Alert/AlertRhAvailable'))
Vue.component('empty-state', EmptyState)
Vue.component('summary-text', Summary)
Vue.component('vue-suggest', VueSuggest)
Vue.component('people', People)
Vue.component('PositionAvatar', PositionAvatar)
Vue.component('AlertDunningVersion', AlertDunningVersion)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('FECIcon', FECIcon)

// Global Filters
Vue.filter('price', filterPrice.price)
Vue.filter('priceHT', filterPrice.priceHT)
Vue.filter('priceTTC', filterPrice.priceTTC)
Vue.filter('date', filtersDate.date)
Vue.filter('datetime', filtersDate.datetime)
Vue.filter('dateFromNow', filtersDate.dateFromNow)
Vue.filter('fullName', filtersName.fullName)
Vue.filter('initials', filtersName.initials)
Vue.filter('capitalize', filtersName.capitalize)
Vue.filter('uppercase', filtersName.uppercase)
Vue.filter('lowercase', filtersName.lowercase)
// Global mixins
Vue.mixin({
  data () {
    return {
      RIGHTS
    }
  },
  computed: {
    ...mapGetters(['currentRights', 'companyReadOnly', 'currentUser', 'currentCompany', 'currentPosition', 'currentSubscription'])
  },
  methods: {
    ...confirmBox,
    ...confirmLeavePage,
    ...toast,
    ...{
      copyClipboard (value, text) {
        try {
          navigator.clipboard.writeText(value)
          this.toastInfo(text, { title: 'Copié' })
        } catch (e) {
          this.toastError({ message: "Le texte n'a pas été copié." })
        }
      },
      validateState (ref) {
        if (
          this.veeFields[ref] &&
          (this.veeFields[ref].dirty || this.veeFields[ref].validated)
        ) {
          return !this.veeErrors.has(ref)
        }
        return null
      },
      onShowPosition (position) { this.$router.push({ name: 'Position', params: { company_url: this.currentCompany.url, id: position._id } }) },
      redirectError,
      price: (item) => filterPrice.price(item),
      priceHT: (item) => filterPrice.priceHT(item),
      priceTTC: (item) => filterPrice.priceTTC(item),
      initials: (item) => filtersName.initials(item),
      fullName: (item) => filtersName.fullName(item),
      capitalize: (item) => filtersName.capitalize(item),
      date: (item, format) => filtersDate.date(item, format)
    }
  }
})

window.addEventListener('error', e => {
  // Si morceau de l'appli manquant, faire f5
  if (/Loading chunk [\d]+ failed/.test(e.message)) {
    window.location.reload()
  }
})

api.autoSigned.service.getLocale({ id: 'fr' }).then(resp => {
  let messages = {}
  if (resp.data) {
    messages[locale] = JSON.parse(JSON.stringify(resp.data))
  }
  // Load Locales
  const i18n = new VueI18n({ locale, messages })

  Vue.use(VeeValidate, {
    // This is the default
    inject: true,
    // Important to name this something other than 'fields'
    fieldsBagName: 'veeFields',
    // This is not required but avoids possible naming conflicts
    errorBagName: 'veeErrors',
    i18nRootKey: 'validations', // customize the root path for validation messages.
    i18n,
    dictionary: {
      fr: validationMessages
    }
  })

  /* eslint-disable no-new */
  new Vue({
    el: '#app',
    router: router(i18n),
    store,
    render: h => h(App),
    i18n
  })
})
