export default (axios, checkHeaders) => ({
  getList (data = {}) {
    return axios.get('/invoice-tva-counts', { params: data.filters || {} })
  },
  postOne (data = {}) {
    return axios.post('/invoice-tva-count', data.body, { params: data.filters || {} })
  },
  getOne (data = {}) {
    return axios.get('/invoice-tva-count/' + data.id, { params: data.filters || {} })
  },
  patchOne (data = {}) {
    return axios.patch('/invoice-tva-count/' + data.id, data.body, { params: data.filters || {} })
  },
  deleteOne (data = {}) {
    return axios.delete('/invoice-tva-count/' + data.id, { params: data.filters || {} })
  }
})
